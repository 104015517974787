import React, { useState, useEffect } from "react";
// import AudioTemplate from "../../global/layout/AudioTemplate";
import StaticSelect from "../../../global/inputs/StaticSelect";
import { useForm } from "react-hook-form";
import dropdownIndicator from "../../../utils/dropdownIndicator";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import { IconOption } from "../../../utils/IconOption";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncTranslation } from "../../../redux/reducers/scenarioReducer";
import {
  updateGlobalValue,
  updateKeyByActivity,
  updateNextButtonStatus,
  updateTranslationValue,
} from "../../../redux/slices/scenarioSlice";
// Import the uuid library
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";  // Import useNavigate

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = scenarioData.global;
  const currentStepperSegment = globalData["current-stepper-segment"];
  const currentLayout = globalData["current-layout"];
  const userId = globalData.id;
  const navigate = useNavigate();  // Initialize useNavigate

  // console.log("Current View:", currentLayout);

  const currentActivityType = scenarioData.global["current-activity-type"];
  // console.log("Current Page:", currentActivityType);

  const root = scenarioData[currentActivityType];

  const languageOptions = root.languages;
  const englishPassage = root["english-passage"];
  // console.log("English Passage:", englishPassage);
  // console.log("Scenario Data:", scenarioData);
  // console.log("ROOT:", root);
  // console.log("Views:", root.views);
  const viewMainData = root.views[currentLayout]?.main;

  // console.log("ViewMainData:", viewMainData);
  const columnsData = viewMainData.columns;
  const columnsNum = columnsData.length;
  // console.log("ColumnsData:", columnsData, columnsNum);
  const leftData = columnsData[0].left;
  const title = leftData.title;
  const body = leftData.body;
  const rightData = columnsData[1].right;
  const [languageSelected, setLanguageSelected] = useState(false);
  // const { Option } = components;

  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    // defaultValues: {
    //   status: {
    //     value: "new",
    //     label: "New",
    //   },
    // },
  });

  const selectHandler = async (data) => {
    console.log("Selection Data:", data);
    const nativeLanguage = data.name.toLowerCase();

    // Store the native language in the Redux passage["native-language"] property.
    dispatch(
      updateKeyByActivity({
        activityType: currentActivityType,
        key: "native-language",
        value: nativeLanguage,
      })
    );

    if (data.label) {
      setLanguageSelected(true);
    }
    // DEPRECATED: store translated version of passage ( No Longer required, as playing audio not read by chatGPT)
    //   const translationData = await dispatch(
    //     fetchAsyncTranslation({ from: "en", to: data.code })
    //   );
  };

  useEffect(() => {
    // Generate a unique ID
    const uniqueId = uuidv4();
    if (uniqueId) {
      dispatch(updateGlobalValue({ key: "id", value: uniqueId }));
      // Update the URL with the userId as a query parameter
      navigate(`?userId=${uniqueId}`);
    }
  }, []);

  useEffect(() => {


    // set passage-translation-complete to false
    dispatch(
      updateTranslationValue({
        key: "complete",
        value: false,
      })
    );

    if (languageSelected) {
      // console.log("Language Selected:", languageSelected);
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
    } else if (!languageSelected) {
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: "language-select",
        })
      );
    }

  }, [languageSelected]);

  return (
    <>
      {/* Render userId with a watermark effect using a CSS class
      {userId && currentStepperSegment === "language-select" && <p className="user-id-watermark">{userId}</p>} */}
      <div className="language-select-container">
        <div className="select instructions">
          <div className="title">{leftData.title}</div>
          <div className="body">{leftData.body}</div>
        </div>
        <StaticSelect
          // menuOpen={true}
          labelClass="selector"
          isMulti={false}
          placeholder="Search languages..."
          name="titles"
          options={languageOptions}
          control={control}
          DropdownIndicator={() =>
            dropdownIndicator(displaySvgIcon("magnifying-glass"))
          }
          IconOption={IconOption}
          card
          selectHandler={selectHandler}
        />
      </div>
    </>
  );
};

export default LanguageSelect;
