import { getTimestamp } from "./getTimeStamp";

import { storage } from "../firebase";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { set } from "react-hook-form";

/********************
 * Upload files to Firebase
 *********************/
export const uploadFiles = async (data, userId, setIsDataUploaded, typeNumber, meta) => {
  if (data === null) return;
  console.log("MyData:", data);

  const textFileName = `pte-a/${userId}/${getTimestamp()}-Type${typeNumber}-${meta ? meta + '-Text' : 'Text'}`;
  const fileRef = ref(storage, textFileName);

  const audioFileName = `pte-a/${userId}/${getTimestamp()}-Type${typeNumber}-${meta ? meta + '-Audio' : '-Audio'}`;
  const audioRef = ref(storage, audioFileName);

  try {
    /****************************************
     * Uploads all TextBlob files to firebase
     * NOTE: Setting isDataUploaded in TextBlob
     * Upload, as all files should have text
     ****************************************/
    await uploadBytes(fileRef, data.textBlob);
    console.log("Uploaded Text!");
    setIsDataUploaded(true);

    /****************************************
     * Uploads all AudioBlob files to firebase
     * Some Types will not have audioBlobs
     ****************************************/
    if (data.audioBlob) {
      await uploadBytes(audioRef, data.audioBlob);
      console.log("Uploaded Audio!");
    }

    return "Upload Successful!";
  } catch (error) {
    console.error("Error during file upload:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};
