// Helper function to combine audio blobs
import {outputAudioBlob} from "./outputAudioBlob.js";

// Helper function to combine audio blobs into a single WAV blob
/**
 * Combines multiple audio blobs into a single audio blob.
 * @param {Blob[]} audioBlobs - An array of audio Blob objects to be combined.
 * @returns {Promise<Blob>} A Promise that resolves to a single audio Blob containing the combined audio data.
 */
// export const combineAudioBlobs = async (audioBlobs) => {
//   // Create an AudioContext, with a fallback for older browsers
//   const audioContext = new (window.AudioContext || window.webkitAudioContext)();

//   // Function to convert a Blob to an ArrayBuffer using FileReader
//   const blobToArrayBuffer = (blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = () => reject(new Error("Failed to convert Blob to ArrayBuffer"));
//       reader.readAsArrayBuffer(blob);
//     });
//   };

//   // Decode each audio blob into an AudioBuffer
//   const buffers = await Promise.all(audioBlobs.map(async (blob) => {
//     const arrayBuffer = await blobToArrayBuffer(blob);
//     return audioContext.decodeAudioData(arrayBuffer);
//   }));
// 8/14/2024
export const combineAudioBlobs = async (audioBlobs) => {
  // Create an AudioContext, with a fallback for older browsers
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
  // Decode each audio blob into an AudioBuffer
  const buffers = await Promise.all(audioBlobs.map(async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    return audioContext.decodeAudioData(arrayBuffer);
  }));
  // Determine the number of channels to use (minimum number across all buffers)
  const numberOfChannels = Math.min(...buffers.map(buffer => buffer.numberOfChannels));
  // Calculate the total length of the combined audio
  const length = buffers.reduce((acc, buffer) => acc + buffer.length, 0);
  // Create a new AudioBuffer to hold the combined audio
  const combinedBuffer = audioContext.createBuffer(numberOfChannels, length, audioContext.sampleRate);
  
  // Offset to track the current position in the combined buffer
  let offset = 0;

  // Copy each buffer's data into the combined buffer
  buffers.forEach(buffer => {
    for (let channel = 0; channel < numberOfChannels; channel++) {
      combinedBuffer.getChannelData(channel).set(buffer.getChannelData(channel), offset);
    }
    offset += buffer.length;
  });

  // Convert the combined AudioBuffer back into a Blob
  const wavBlob = await outputAudioBlob(audioContext, combinedBuffer);
  return wavBlob;
};
