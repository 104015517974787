import React, { useRef, useEffect } from "react";
import Footer from "../widgets/Footer";
import { useDispatch, useSelector } from "react-redux";

import displaySvgIcon from "../../utils/displaySvgIcon";
import volumeSlider from "../../assets/images/volume.png";
import Stepper from "../widgets/Stepper";
import LanguageSelect from "../../layout/content/translation/LanguageSelect";
import Instructions from "../../layout/content/global/Instructions";
// import ListenCountdown from "../../layout/content/translation/ListenCountdown";
import PassageForeign from "../../layout/content/translation/PassageForeign";
// import TranslationCountdown from "../../layout/content/translation/TranslationCountdown";
import PassageEnglish from "../../layout/content/translation/PassageEnglish";
import Translation from "../../page/Translation";
import Advice from "../../page/Advice";
import Chat from "../../page/Chat";
import Opinion from "../../page/Opinion";
import Completion from "../../page/Completion";

// import SigninFormContent from "../../layout/content/translation/SigninFormContent";
// import CreateAccountContent from "../../layout/content/translation/CreateAccountContent";
import {
  updateTranslationValue,
  updateGlobalValue,
} from "../../redux/slices/scenarioSlice";
import AdviceInstructionReview from "../../layout/content/advice/AdviceInstructionReview";
import Backdrop from "../outputs/Backdrop";
import ChatInstructionReview from "../../layout/content/chat/ChatInstructionReview";
import OpinionInstructionReview from "../../layout/content/opinion/OpinionInstructionReview";
import CompletionPage from "../../layout/content/global/CompletionPage";

/***************************
 * GenericTemplate display the
 * correct view based on the 
 * currentLabout name in redux
 *****************************/
const GenericTemplate = ({ children }) => {
  const nextBtnClicked = useRef(false);
  const dispatch = useDispatch();
  console.log("GENERIC TEMAPLATE re-rendered");
  const nextBtnRef = useRef(null);

  /************************
   * Map the current layout-name 
   * to its actual display component
   *************************/
  const PageMap = {

    "language-select": LanguageSelect,
    instructions: Instructions,
    "passage-foreign": PassageForeign,
    "passage-english": PassageEnglish,

    "advice-instructions": Instructions,
    "advice-instruction-review": AdviceInstructionReview,

    "chat-instructions": Instructions,
    "chat-instruction-review": ChatInstructionReview,

    "chat-written-instructions": Instructions,
    "chat-written-instruction-review": ChatInstructionReview,

    "opinion-instructions": Instructions,
    "opinion-instruction-review": OpinionInstructionReview,

    "completion": CompletionPage

  };

  const isSignedIn = useSelector(
    (state) => state.scenarios.global["signed-in"]
  );

  const currentLayout = useSelector(
    (state) => state.scenarios.global["current-layout"]
  );
  const currentActivityType = useSelector(
    (state) => state.scenarios.global["current-activity-type"]
  );

  console.log("CurrentView1: ", currentLayout, "CurrentPage1: ", currentActivityType);
  const DisplayElement = PageMap[currentLayout];

 let microphoneImage= <></>;
 let BackDrop = <div></div>;


  // useEffect(() => {
  //   if (isSignedIn && currentActivityType === "passage") {
  //     dispatch(
  //       updateGlobalValue({ key: "current-layout", value: "language-select" })
  //     );
  //   }
  // }, []);

  return (
    <>
      {currentActivityType === "passage" ? (
        <Translation>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            />
        </Translation>
      ) : (currentActivityType === "advice" ? (
        <Advice>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            BackDrop={BackDrop}
        />   
        </Advice>) : (currentActivityType === "chat" ? (
        <Chat>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            BackDrop={BackDrop}
            nextBtnClicked={nextBtnClicked}
            key="00"
        />   
        </Chat>) : (currentActivityType === "chat-written" ? (
        <Chat>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            BackDrop={BackDrop}
            nextBtnClicked={nextBtnClicked}
            key="01"
        />   
        </Chat>) : (currentActivityType === "opinion" ? (
        <Opinion>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            BackDrop={BackDrop}
            nextBtnClicked={nextBtnClicked}
        /> 
          </Opinion>) :(currentActivityType === "completion" ? (
        <Completion>
          <DisplayElement 
            nextBtnRef={nextBtnRef} 
            BackDrop={BackDrop}
            nextBtnClicked={nextBtnClicked}
        /> 
          </Completion>) :<></>)))
      ))}
      
        <Footer 
          nextBtnRef={nextBtnRef} 
          nextBtnClicked={nextBtnClicked}
        />
    </>
  );
};

export default GenericTemplate;
