import React, { useEffect, useState, useRef } from "react";
import MicrophoneSpeakTimer2 from "../../global/outputs/MicrophoneSpeakTimer2";
import SilentMicrophoneAnimation from "../../global/widgets/SilentMicrophoneAnimation";
import VoiceMicrophoneAnimation from "./VoiceMicrophoneAnimation";
import MicComplete from "../../global/widgets/MicComplete";
import displaySvgIcon from "../../utils/displaySvgIcon";
import { updateGlobalValue } from "../../redux/slices/scenarioSlice";
import { useSelector, useDispatch } from "react-redux";

/**
 * ChatActiveMicrophone Component: Resposible for setting userStoppedTalking
 * In order to stop the Recording state
 *
 * This component is responsible for managing the display and state of the microphone during a recording session.
 * It conditionally renders different microphone states based on the props passed to it.
 * - When `isTimerComplete` is false, it displays an inactive microphone icon.
 * - When `isTimerComplete` is true and `type` is "silent" and `isDataUploaded` is false, it displays an animated microphone ready state.
 * - When `isTimerComplete` is true and `type` is "voice" and `isDataUploaded` is false, it displays a microphone toggle state.
 * - When `isTimerComplete` is true and `isDataUploaded` is true, it displays a microphone completion state.
 * Additionally, it includes the `MicrophoneSpeakTimer2` component to display the countdown timer during the recording.
 * IMPORTANT: CALL MICROPHONESPEAKTIMER2, which is required to update time in the CircularProgressIndicator2 component.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.stepperLabel] - The label for the current step in the stepper. It is used in Type1 only.
 * @param {boolean} props.isTimerComplete - Flag indicating if the timer is complete.
 * @param {string} props.type - The type of microphone state ("silent" or "voice").
 * @param {boolean} props.isDataUploaded - Flag indicating if the data is uploaded.
 * @param {function} props.setUserSpeakTimeComplete - Callback to set the speak time complete state.
 * @param {number} props.recordingDuration - The initial time for the microphone record timer.
 * @returns {JSX.Element} The rendered component.
 */
const ChatActiveMicrophone = ({
  isTimerComplete,
  type,
  toggle,
  prevType,
  isDataUploaded,
  classLabel,
  stepperLabel,
  startedTalking,
  systemSpeaking,
  isSilentForFiveSeconds,
  isTalkingForFiveSeconds,
  userStoppedTalking,
  setUserStoppedTalking,
  isRecording,
  submitBtnClicked,
  userStoppedTalkingRef,
}) => {
  const scenarioData = useSelector((state) => state.scenarios);
  const currentActivityType = scenarioData.global["current-activity-type"];
  const dispatch = useDispatch();
  // const userStoppedTalkingRef = useRef(userStoppedTalking);

  // useEffect(() => {
  //   if (userStoppedTalkingRef.current !== userStoppedTalking) {
  //     userStoppedTalkingRef.current = userStoppedTalking;
  //     // console.log("User Stopped Talking:", userStoppedTalking);
  //   }
  // }, [userStoppedTalking]);

  // useEffect(() => {
  //   const userStoppedTalking = type === "silent" && prevType === "voice";
  //   console.log(
  //     "SILENT: isSilentForFiveSeconds:",
  //     isSilentForFiveSeconds,
  //     "isTalkingForFiveSeconds",
  //     isTalkingForFiveSeconds,
  //     "Type:",
  //     type,
  //     "prevType:",
  //     prevType,
  //     "isRecording:",
  //     isRecording,
  //     "isDataUploaded:",
  //     isDataUploaded,
  //     "systemSpeaking:",
  //     systemSpeaking, 
  //     "userStoppedTalking:",
  //     userStoppedTalking
  //   )
  //   // console.log("MIC Type:", type, "PREV TYPE:", prevType)
  //   // if (type === "voice" && !submitBtnClicked) {
  //     if (type === "voice" ) {

  //     console.log(
  //       "Setting userStoppedTalking to false",
  //       ":SubmitButtonClicked:",
  //       submitBtnClicked
  //     );
  //     setUserStoppedTalking(false); // Reset the state when it turns voice
  //     userStoppedTalkingRef.current = false;
  //   // } else if (type === "silent" && userStoppedTalking && !submitBtnClicked) {
  //   } else if (type === "silent" && userStoppedTalking ) {
  //     console.log(
  //       "Setting userStoppedTalking to true",
  //       ":SubmitButtonClicked:",
  //       submitBtnClicked
  //     );
  //     setUserStoppedTalking(true); // Set the state when it turns silent after voice
  //     userStoppedTalkingRef.current = true;
  //   } 
  //   // else if (submitBtnClicked) {
  //   //   console.log(
  //   //     "ChatActiveMicrophone: Submit Button Clicked: ButtonClicke:",
  //   //     submitBtnClicked,
  //   //     " Reseting state"
  //   //   );
  //   //   userStoppedTalkingRef.current = false;
  //   // }
  // }, [type, toggle]);

  // useEffect(() => {
  //   if (stepperLabel !== undefined) {
  //     dispatch(
  //       updateGlobalValue({
  //         key: "current-stepper-segment",
  //         value: stepperLabel,
  //       })
  //     );
  //   }
  // }, [dispatch, stepperLabel]);

  // // console.log("Stepper Label", stepperLabel);
  // useEffect(() => {
  //   if (userStoppedTalking) {
  //     // console.log("userStoppedTalking:", userStoppedTalking);
  //   }
  // }, [userStoppedTalking]);

  return (
    <>
      {
        (!startedTalking &&
          type === "silent" &&
          systemSpeaking &&
          !isRecording) ||
        systemSpeaking ? (
          /***************************
           * (1) INACTIVE MICROPHONE
           ***************************/
          <div className="microphone-icon">
            {displaySvgIcon("mini-inactive-microphone")}
          </div>
        ) : isTimerComplete &&
          type === "silent" &&
          !isDataUploaded &&
          !systemSpeaking &&
          isRecording &&
          // (!userStoppedTalking || !userStoppedTalkingRef.current) ? (
            (!userStoppedTalking) ? (

          <>
            <SilentMicrophoneAnimation classLabel="microphone-icon" />
          </>
        ) : // ) : (isTimerComplete && type === "voice" && !isDataUploaded && !systemSpeaking? (
        isTimerComplete && type === "voice" && !systemSpeaking ? (
          <VoiceMicrophoneAnimation classLabel="microphone-icon" />
        ) : isTimerComplete &&
          isDataUploaded &&
          !systemSpeaking &&
          isDataUploaded ? (
          <MicComplete />
        ) : (
          <div className="microphone-icon">
            {displaySvgIcon("mini-inactive-microphone")}
          </div>
        )
        //  : (
        //   // <MicComplete />
        // )
      }

      {isTimerComplete ? (
        currentActivityType === "chat" || currentActivityType === "opinion" ? (
          <MicrophoneSpeakTimer2
            // setSpeakTimeComplete={setSpeakTimeComplete}
            endTime={0}
            isDataUploaded={isDataUploaded}
            isTimerComplete={isTimerComplete}
            disableCircularProgressbar={true}
            classLabel={
              isTimerComplete &&
              type === "silent" &&
              !isDataUploaded &&
              !systemSpeaking &&
              isRecording &&
              // (!userStoppedTalking || !userStoppedTalkingRef.current)
              (!userStoppedTalking)

                ? "show-speaking"
                : "hidden-element"
            }
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatActiveMicrophone;
