import React from "react";
import AudioTemplate from "../global/wrappers/AudioTemplate";
import { useDispatch, useSelector } from "react-redux";

const Chat = ({children}) => {
  console.log("Advice re-rendered");
  const currentLayout = useSelector(
    (state) => state.scenarios.global["current-layout"]
  );
  const currentPage = useSelector(
    (state) => state.scenarios.global["current-page"]
  );

  return (
    <div className={(
      (currentPage === "chat" && currentLayout !=="chat-instructions") || 
      (currentPage === "chat" && currentLayout !=="chat-instructions"))? "page chat" : "page"}>
       {children}
      </div>
)};

export default Chat;
