import { useEffect, useState, useRef, useCallback } from "react";
import { calculateRMS, SILENCE_THRESHOLD } from "../OpenAi";

const useCheckSilence = (analyser, isRecording, dataArray, timerComplete) => {
  const [isVoiceSilent, setIsVoiceSilent] = useState(false);
  const [isSilentForFiveSeconds, setIsSilentForFiveSeconds] = useState(false);
  const [isTalkingForFiveSeconds, setIsTalkingForFiveSeconds] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [type, setType] = useState("silent");
  const [startedTalking, setStartedTalking] = useState(false);
  const animationFrameId = useRef(null);
  const silenceTimer = useRef(null);
  const soundCount = useRef(0);
  const hasStartedTalking = useRef(false);
  const rmsRef = useRef(0);

  const checkSilence = useCallback(() => {
    // console.log("checkSilence called with timerComplete:", timerComplete);
    if (!analyser || !(dataArray instanceof Uint8Array) || !isRecording || !timerComplete) {
      setIsVoiceSilent(false);
      setIsSilentForFiveSeconds(false);
      return;
    }

    analyser.getByteTimeDomainData(dataArray);
    rmsRef.current= calculateRMS(dataArray);
    // console.log("RMS value:", rms);
    const withinSilenceThreshold = rmsRef.current < SILENCE_THRESHOLD;
    setIsVoiceSilent(withinSilenceThreshold);

    if (!withinSilenceThreshold) {
      soundCount.current += 1;
      if (!hasStartedTalking.current) {
        hasStartedTalking.current = true;
        setStartedTalking(true);
      }
    }

    if (isRecording && timerComplete) {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      animationFrameId.current = requestAnimationFrame(checkSilence);
    }
  }, [analyser, dataArray, isRecording, timerComplete]);

  /**********Reset Hook*/
  const reset = useCallback(() => {
    setIsVoiceSilent(false);
    setIsSilentForFiveSeconds(false);
    setIsTalkingForFiveSeconds(false);
    setToggle(false);
    setType("silent");
    setStartedTalking(false);
    soundCount.current = 0;
    hasStartedTalking.current = false;
    rmsRef.current = 0;

    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }

    if (silenceTimer.current) {
      clearTimeout(silenceTimer.current);
      silenceTimer.current = null;
    }

    // Restart checkSilence
    if (isRecording && timerComplete) {
      checkSilence();
    }
  }, [checkSilence, isRecording, timerComplete]);

  useEffect(() => {
    if (isRecording && timerComplete) {
      checkSilence();
    } else if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [checkSilence, isRecording, timerComplete]);

  useEffect(() => {
    if (isVoiceSilent && timerComplete) {
      silenceTimer.current = setTimeout(() => {
        setIsSilentForFiveSeconds(true);
        setToggle(prev => !prev); // Toggle changes to trigger data update
      }, 5000);
    } else {
      clearTimeout(silenceTimer.current);
      setIsSilentForFiveSeconds(false);
    }

    return () => {
      clearTimeout(silenceTimer.current);
    };
  }, [isVoiceSilent, timerComplete]);

  useEffect(() => {
    if (isSilentForFiveSeconds && timerComplete) {
      setTimeout(() => {
        setIsVoiceSilent(false);
      }, 100); // Short delay to ensure the caller has processed the values
    }
  }, [isSilentForFiveSeconds, timerComplete]);

  useEffect(() => {
    if (isRecording && timerComplete) {
      checkSilence();
    }
  }, [isRecording, timerComplete]);

  useEffect(() => {
    if(timerComplete) {
    const intervalId = setInterval(() => {
      if (soundCount.current >= 5) {
        setIsTalkingForFiveSeconds(true);
        setType("voice");
      } else {
        setIsTalkingForFiveSeconds(false);
        if (!isSilentForFiveSeconds) {
          setType("silent");
        }
      }
      soundCount.current = 0;
    }, 5000);

    return () => clearInterval(intervalId);
  }
  }, [toggle, timerComplete]);

  useEffect(() => {
    if (isSilentForFiveSeconds && timerComplete) {
      setType("silent");
    }
  }, [isSilentForFiveSeconds, timerComplete]);
// console.log("Sending TimerComplete:", timerComplete, "startedTalking:", startedTalking, "isSilentForFiveSeconds:", isSilentForFiveSeconds, "isTalkingForFiveSeconds:",isTalkingForFiveSeconds, "type:", type, "toggle:", toggle, "isRecoding:", isRecording, "RMS:", rmsRef.current); 
  return { isSilentForFiveSeconds, isTalkingForFiveSeconds, toggle, type, startedTalking, rms: rmsRef.current, reset };
};

export default useCheckSilence;
