import React, { useEffect, useState, useRef } from "react";
import Rive, {
  useRive,
  useStateMachineInput,
  RiveRef,
  Layout,
  Fit,
  Alignment,
} from "@rive-app/react-canvas";
import System from "../../../assets/riv/pte_chat1.riv";

const SystemSpeaking= () => {
  // console.log("Current Action in SystemActive:", animation);
  // const [isPlaying, setIsPlaying] = useState(true);

  let { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: System,
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "Speaking",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitCover, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  useEffect(() => {
    if (rive) {
      // console.log("SpeakingSystem0:", rive?.contents);
    }
  }, [rive]);

  return (
    // <div className="base">
    <div style={{ width: "538px", height: "140px" }}>
      <RiveComponent
      // style={{  width: "100%", height: "140px" }}
      // onClick={() => bumpInput && bumpInput.trigger()}
      // onMouseEnter={() => rive && rive.play()}
      // onMouseLeave={() => rive && rive.pause()}
      />
      </div>
    //  </div>

    // <div style={{ zIndex: 10000, width: "538px", height: "140px" }}>
    // <Rive src={System} animation="Thinking" />
    //</div>

    // <div style={{ zIndex: 10000, width: "538px", height: "140px" }}>
    // <RiveComponent />
    // </div>
  );
};

export default SystemSpeaking;
