import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setNativeLanguageReducer = (state, action) => {
  const selectedLanguage = action.payload;
  state.passage.nativeLanguage = selectedLanguage;

  // Copy the corresponding audios array into translations_copy
  if (state.passage["audio-translations"][selectedLanguage]?.audios) {
    state.passage["translations_copy"] = [...state.passage["audio-translations"][selectedLanguage].audios];
  }
};

export const setUserReducer = (state, action) => {
  const updatedUser = action.payload;
  const keys = Object.keys(updatedUser);

  const newGlobal = { ...state.global };

  keys.forEach((key) => {
    if (key && updatedUser[key] !== undefined && updatedUser[key] !== null) {
      newGlobal[key] = updatedUser[key];
    }
  });

  state.global = newGlobal;
};


export const getScenarioReducer = (state, action) => {
  return state; // Assuming 'user' is the key for user state in Redux store
};

export const updateNextBtnStatusReducer = (state, action) => {
  const { key, value } = action.payload;

  if (state.global.hasOwnProperty(key)) {
    state.global = {
      ...state.global,
      [key]: value
    };
  } else {
    console.warn(`Key '${key}' does not exist in the state object.`);
  }
};


export const updateSilenceReducer = (state, action) => {
  const { isSilent } = action.payload;

  state.passage = {
    ...state.passage,
    isSilent: isSilent
  };
};


export const updateTranslationReducer = (state, action) => {
  const { key, value } = action.payload;

  if (state.passage.hasOwnProperty(key)) {
    state.passage = {
      ...state.passage,
      [key]: value
    };
  } else {
    console.warn(`Key '${key}' does not exist in the state object.`);
  }
};


export const updateAdviceReducer = (state, action) => {
  const { key, value } = action.payload;

  if (state.advice.hasOwnProperty(key)) {
    state.advice = {
      ...state.advice,
      [key]: value
    };
  } else {
    console.warn(`Key '${key}' does not exist in the state object.`);
  }
};
export const updateKeyByActivityReducer = (state, action) => {
  const { activityType, key, value } = action.payload;

  // Check if the activityType exists in state
  if (state[activityType]) {
    // Check if the key exists in the activityType object
    if (state[activityType].hasOwnProperty(key)) {
      state[activityType] = {
        ...state[activityType],
        [key]: value,
      };
    } else {
      console.warn(`Key '${key}' does not exist in the state[${activityType}] object.`);
    }
  } else {
    console.warn(`Activity type '${activityType}' does not exist in the state.`);
  }
};



/**
 * Reducer to update the completion status of a shifted item in a specified slice.
 * 
 * @param {Object} state - The Redux state.
 * @param {Object} action - The action object containing the payload.
 * @param {Object} action.payload - The payload object containing slice information.
 * @param {string} action.payload["activity-type"] - The slice name to update.
 * @param {boolean} action.payload.complete - The completion value to set.
 */
export const updateCompleteScenarioByTypeReducer = (state, action) => {
  const { activityType, complete } = action.payload;

  // Ensure the sliceName exists in the state
  if (state[activityType]) {
    state[activityType].shiftedItem = {
      ...state[activityType].shiftedItem,
      complete
    };
  } else {
    console.warn(`Activity Type "${activityType}" not found in state.`);
  }
};

// export const clearAdviceScenarioShiftedItemReducer = (state, action) => {
//   // const { value } = action.payload;
//   state.advice.shiftedItem = {};
// };


export const updateGlobalReducer = (state, action) => {
  const { key, value } = action.payload;

  if (state.global.hasOwnProperty(key)) {
    state.global = {
      ...state.global,
      [key]: value
    };
  } else {
    console.warn(`Key '${key}' does not exist in the state object.`);
  }
};

export const updateMultipleGlobalReducer = (state, action) => {
  const updates = action.payload;

  if (typeof updates === 'object' && updates !== null) {
    const newGlobal = { ...state.global };

    for (const key in updates) {
      if (updates.hasOwnProperty(key)) {
        if (newGlobal.hasOwnProperty(key)) {
          newGlobal[key] = updates[key];
        } else {
          console.warn(`Key '${key}' does not exist in the state object.`);
        }
      }
    }

    state.global = newGlobal;
  } else {
    console.warn("Payload should be an object.");
  }
};


// Generates pending, fulfilled, or rejected action types that are auto dispatched
export const fetchAsyncTranslation = createAsyncThunk(
  "pseudocode/fetchTranslation",
  async ({ from, to }) => {
    // const text = `John's new house is near a train station. Every morning a train comes into the station and blows its horn, waking John up. For the first few months of living there, John could use the horn as an alarm clock. However, now he is used to the sound of the horn and he sleeps through it. So, he needs to use an alarm clock.`;
const text = "Hello, world!";
    // console.log("Text:", text, "From:", from, "To:", to);
    // console.log("Key:", process.env.REACT_APP_OcpApimSubscriptionKey);
    const translatorUrl =
      "https://api.cognitive.microsofttranslator.com/translate?api-version=3.0";
    const response = await fetch(`${translatorUrl}&from=${from}&to=${to}`, {
      method: "POST",
      // body: text,
      body: JSON.stringify([{ Text: text }]),
      headers: {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_OcpApimSubscriptionKey,
        "Ocp-Apim-Subscription-Region": "eastus2",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });

    try {
      if (response.ok) {
        const json = await response.json();
        // console.log("JSON:", json);
        return json[0].translations[0].text;
        // return resp
      } else {
        // console.log("Response:", response);
        throw new Error("Failed to fetch translation");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

