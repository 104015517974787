import { serializeImage } from "../../utils/serializeImage";

import birthday2 from "../../assets/images/chat/scenario0/birthday-place.png";
import birthday3 from "../../assets/images/chat/scenario0/birthday-meal.png";
import birthday4 from "../../assets/images/chat/scenario0/birthday-activity.png";

import birthday6 from "../../assets/images/chat/scenario0/birthday-new-place.png";
import birthday7 from "../../assets/images/chat/scenario0/birthday-meal.png";
import birthday8 from "../../assets/images/chat/scenario0/birthday-new-activity.png";

import assignment from "../../assets/images/chat/scenario1/assignment-submission.png";

import office1 from "../../assets/images/chat/scenario2/office-area.png";
import office2 from "../../assets/images/chat/scenario2/office-conference.png";
import office3 from "../../assets/images/chat/scenario2/office-cafeteria.png";
import office4 from "../../assets/images/chat/scenario2/office-relaxation.png";

import office5 from "../../assets/images/chat/scenario2/office-new-area.png";
import office6 from "../../assets/images/chat/scenario2/office-new-conference.png";
import office7 from "../../assets/images/chat/scenario2/office-new-cafeteria.png";
import office8 from "../../assets/images/chat/scenario2/office-new-relaxation.png";

import lifestyle2 from "../../assets/images/chat/scenario3/lifestyle-exercise.png";
import lifestyle3 from "../../assets/images/chat/scenario3/lifestyle-work.png";
import lifestyle4 from "../../assets/images/chat/scenario3/lifestyle-hobby.png";

import lifestyle6 from "../../assets/images/chat/scenario3/lifestyle-new-exercise.png";
import lifestyle7 from "../../assets/images/chat/scenario3/lifestyle-new-work.png";
import lifestyle8 from "../../assets/images/chat/scenario3/lifestyle-new-hobby.png";

import safari1 from "../../assets/images/chat/scenario4/safari-meal.png";
import safari2 from "../../assets/images/chat/scenario4/safari-lodging.png";
import safari3 from "../../assets/images/chat/scenario4/safari-tour.png";
import safari4 from "../../assets/images/chat/scenario4/safari-weather.png";

import safari5 from "../../assets/images/chat/scenario4/safari-actual-meal.png";
import safari6 from "../../assets/images/chat/scenario4/safari-actual-lodging.png";
import safari7 from "../../assets/images/chat/scenario4/safari-actual-tour.png";
import safari8 from "../../assets/images/chat/scenario4/safari-actual-weather.png";

import sunburn1 from "../../assets/images/advice/scenario0/sunburn-sunscreen.png";
import sunburn2 from "../../assets/images/advice/scenario0/sunburn-rim-hat.png";
import sunburn3 from "../../assets/images/advice/scenario0/sunburn-umbrella.png";
import sunburn4 from "../../assets/images/advice/scenario0/sunburn-shades.png";
import sunburn5 from "../../assets/images/advice/scenario0/sunburn-clock.png";
import sunburn6 from "../../assets/images/advice/scenario0/sunburn-sleeves.png";

import backpain1 from "../../assets/images/advice/scenario1/backpain-heels.png";
import backpain2 from "../../assets/images/advice/scenario1/backpain-carry.png";
import backpain3 from "../../assets/images/advice/scenario1/backpain-ergonomic.png";
import backpain4 from "../../assets/images/advice/scenario1/backpain-sit.png";
import backpain5 from "../../assets/images/advice/scenario1/backpain-stretch.png";
import backpain6 from "../../assets/images/advice/scenario1/backpain-pickup.png";

import trip1 from "../../assets/images/advice/scenario4/trip-fishing.png";
import trip2 from "../../assets/images/advice/scenario4/trip-waterfall.png";
import trip3 from "../../assets/images/advice/scenario4/trip-cows.png";
import trip4 from "../../assets/images/advice/scenario4/trip-canoe.png";
import trip5 from "../../assets/images/advice/scenario4/trip-hike.png";
import trip6 from "../../assets/images/advice/scenario4/trip-bike.png";

import backpack1 from "../../assets/images/advice/scenario2/backpack-inside.png";
import backpack2 from "../../assets/images/advice/scenario2/backpack-laptop.png";
import backpack3 from "../../assets/images/advice/scenario2/backpack-charger.png";
import backpack4 from "../../assets/images/advice/scenario2/backpack-zipper.png";
import backpack5 from "../../assets/images/advice/scenario2/backpack-orange.png";
import backpack6 from "../../assets/images/advice/scenario2/backpack-waterbottle.png";

import student1 from "../../assets/images/advice/scenario3/student-food.png";
import student2 from "../../assets/images/advice/scenario3/student-house.png";
import student3 from "../../assets/images/advice/scenario3/student-phone.png";
import student4 from "../../assets/images/advice/scenario3/student-grandpa.png";
import student5 from "../../assets/images/advice/scenario3/student-meet.png";
import student6 from "../../assets/images/advice/scenario3/student-kids.png";

import studyLocation1 from "../../assets/images/opinion/scenario1/study-location-coffee.png";
import studyLocation2 from "../../assets/images/opinion/scenario1/study-location-library.png";
import studyLocation3 from "../../assets/images/opinion/scenario1/study-location-friends-home.png";
import studyLocation4 from "../../assets/images/opinion/scenario1/study-location-campus.png";

import tapWater1 from "../../assets/images/opinion/scenario2/tap-water-faucet.svg";
import tapWater2 from "../../assets/images/opinion/scenario2/tap-water-person.svg";
import tapWater3 from "../../assets/images/opinion/scenario2/tap-water-nonpotable.svg";
import tapWater4 from "../../assets/images/opinion/scenario2/tap-water-unsafe-water.svg";

import partTimeJob1 from "../../assets/images/opinion/scenario3/job-uber.png";
import partTimeJob2 from "../../assets/images/opinion/scenario3/job-fast-food.png";
import partTimeJob3 from "../../assets/images/opinion/scenario3/job-ups.png";
import partTimeJob4 from "../../assets/images/opinion/scenario3/job-baby-sitting.png";

import petOption1 from "../../assets/images/opinion/scenario4/pet-dog.png";
import petOption2 from "../../assets/images/opinion/scenario4/pet-parrot.png";
import petOption3 from "../../assets/images/opinion/scenario4/pet-snake.png";
import petOption4 from "../../assets/images/opinion/scenario4/pet-fish.png";

import clothingOptions1 from "../../assets/images/opinion/scenario5/clothing-options-pants-suit.png";
import clothingOptions2 from "../../assets/images/opinion/scenario5/clothing-options-pants-high.png";
import clothingOptions3 from "../../assets/images/opinion/scenario5/clothing-options-skirt.png";
import clothingOptions4 from "../../assets/images/opinion/scenario5/clothing-options-casual.png";

import presentation1 from "../../assets/images/opinion/scenario6/presentation-handout.png";
import presentation2 from "../../assets/images/opinion/scenario6/presentation-images.png";
import presentation3 from "../../assets/images/opinion/scenario6/presentation-talk.png";
import presentation4 from "../../assets/images/opinion/scenario6/presentation-workshop.png";

import koreanFlag from "../../assets/images/translation/korean-flag.png";
import japaneseFlag from "../../assets/images/translation/japanese-flag.png";
import chineseFlag from "../../assets/images/translation/chinese-flag.png";
import spanishFlag from "../../assets/images/translation/spanish-flag.png";
import portugueseFlag from "../../assets/images/translation/portuguese-flag.png";
import indianFlag from "../../assets/images/translation/indian-flag.png";
import englishFlag from "../../assets/images/translation/english-flag.png";

import displaySvgIcon from "../../utils/displaySvgIcon";


const birthday1 = {
    data: [
        { text: 'Friday, May 3', className: 'title' },
        { text: '6 - 10 pm', className: 'time' }
    ],
    type: "text",
    plan: 'original',
    icon: 'calendar'
};

const birthday5 = {
    data: [
        { text: 'Saturday, May 4', className: 'title' },
        { text: '4 - 10 pm', className: 'time' }
    ],
    type: "text",
    plan: 'new',
    icon: 'calendar'
};

const assignment1 = {
    data: [
        { text: 'Due Date:', className: 'subtitle' },
        { text: 'Friday, May 3', className: 'title' }
    ],
    type: "text",
    plan: 'original',
    icon: 'calendar'
}

const assignment2 = {
    data: [
        { text: 'Length:', className: 'subtitle' },
        { text: '4-5 pages', className: 'title' }
    ],
    type: "text",
    plan: 'original',
    icon: 'document'
}

const assignment3 = {
    data: [
        { text: 'How to submit:', className: 'subtitle' },
        { url: assignment, className: 'image-description' },
    ],
    type: "text",
    plan: 'original',
}

const assignment4 = {
    data: [
        { text: 'Grading:', className: 'subtitle' },
        { text: '4-5 pages', className: 'title' }
    ],
    type: "text",
    plan: 'original',
    icon: 'document'
}

const assignment5 = {
    data: [
        { text: 'Due Date:', className: 'subtitle' },
        { text: 'Friday, May 17', className: 'title' }
    ],
    type: "text",
    plan: 'new',
    icon: 'calendar'
}

const assignment6 = {
    data: [
        { text: 'Length:', className: 'subtitle' },
        { text: '1000-1500 words', className: 'title' }
    ],
    type: "text",
    plan: 'new',
    icon: 'document'
}

const assignment7 = {
    data: [
        { text: 'How to submit:', className: 'subtitle' },
        { url: assignment, className: 'image-description' },
    ],
    type: "text",
    plan: 'new',
}

const assignment8 = {
    data: [
        { text: 'Grading:', className: 'subtitle' },
        { text: 'Clear introduction, Cite sources and evidence, Strong argument', className: 'list' }
    ],
    type: "text",
    plan: 'new',
    icon: 'document'
}

const lifestyle1 = {
    data: [
        { text: '1am - 7am', className: 'title' },
    ],
    type: "text",
    plan: 'original',
    icon: 'alarm'
}

const lifestyle5 = {
    data: [
        { text: '11pm - 7am', className: 'title' },
    ],
    type: "text",
    plan: 'new',
    icon: 'alarm'
}

export const korean = serializeImage(koreanFlag);
export const japanese = serializeImage(japaneseFlag);
export const chinese = serializeImage(chineseFlag);
export const spanish = serializeImage(spanishFlag);
export const portuguese = serializeImage(portugueseFlag);
export const indian = serializeImage(indianFlag);
export const english = serializeImage(englishFlag);


export const birth1 = birthday1;
export const birth2 = serializeImage(birthday2);
export const birth3 = serializeImage(birthday3);
export const birth4 = serializeImage(birthday4);
export const birth5 = birthday5;
export const birth6 = serializeImage(birthday6);
export const birth7 = serializeImage(birthday7);
export const birth8 = serializeImage(birthday8);

export const sun1 = serializeImage(sunburn1);
export const sun2 = serializeImage(sunburn2);
export const sun3 = serializeImage(sunburn3);
export const sun4 = serializeImage(sunburn4);
export const sun5 = serializeImage(sunburn5);
export const sun6 = serializeImage(sunburn6);

export const back1 = serializeImage(backpain1);
export const back2 = serializeImage(backpain2);
export const back3 = serializeImage(backpain3);
export const back4 = serializeImage(backpain4);
export const back5 = serializeImage(backpain5);
export const back6 = serializeImage(backpain6);

export const bag1 = serializeImage(backpack1);
export const bag2 = serializeImage(backpack2);
export const bag3 = serializeImage(backpack3);
export const bag4 = serializeImage(backpack4);
export const bag5 = serializeImage(backpack5);
export const bag6 = serializeImage(backpack6);

export const exchange1 = serializeImage(student1);
export const exchange2 = serializeImage(student2);
export const exchange3 = serializeImage(student3);
export const exchange4 = serializeImage(student4);
export const exchange5 = serializeImage(student5);
export const exchange6 = serializeImage(student6);

export const tp1 = serializeImage(trip1);
export const tp2 = serializeImage(trip2);
export const tp3 = serializeImage(trip3);
export const tp4 = serializeImage(trip4);
export const tp5 = serializeImage(trip5);
export const tp6 = serializeImage(trip6);

export const off1 = serializeImage(office1);
export const off2 = serializeImage(office2);
export const off3 = serializeImage(office3);
export const off4 = serializeImage(office4);
export const off5 = serializeImage(office5);
export const off6 = serializeImage(office6);
export const off7 = serializeImage(office7);
export const off8 = serializeImage(office8);

export const life1 = lifestyle1;
export const life2 = serializeImage(lifestyle2);
export const life3 = serializeImage(lifestyle3);
export const life4 = serializeImage(lifestyle4);
export const life5 = lifestyle5;
export const life6 = serializeImage(lifestyle6);
export const life7 = serializeImage(lifestyle7);
export const life8 = serializeImage(lifestyle8);

export const saf1 = serializeImage(safari1);
export const saf2 = serializeImage(safari2);
export const saf3 = serializeImage(safari3);
export const saf4 = serializeImage(safari4);
export const saf5 = serializeImage(safari5);
export const saf6 = serializeImage(safari6);
export const saf7 = serializeImage(safari7);
export const saf8 = serializeImage(safari8);

export const assign1 = assignment1;
export const assign2 = assignment2;
export const assign3 = assignment3;
export const assign4 = assignment4;
export const assign5 = assignment5;
export const assign6 = assignment6;
export const assign7 = assignment7;
export const assign8 = assignment8;


export const study1 = serializeImage(studyLocation1);
export const study2 = serializeImage(studyLocation2);
export const study3 = serializeImage(studyLocation3);
export const study4 = serializeImage(studyLocation4);

export const tap1 = serializeImage(tapWater1);
export const tap2 = serializeImage(tapWater2);
export const tap3 = serializeImage(tapWater3);
export const tap4 = serializeImage(tapWater4);

export const job1 = serializeImage(partTimeJob1);
export const job2 = serializeImage(partTimeJob2);
export const job3 = serializeImage(partTimeJob3);
export const job4 = serializeImage(partTimeJob4);

export const pet1 = serializeImage(petOption1);
export const pet2 = serializeImage(petOption2);
export const pet3 = serializeImage(petOption3);
export const pet4 = serializeImage(petOption4);

export const cloth1 = serializeImage(clothingOptions1);
export const cloth2 = serializeImage(clothingOptions2);
export const cloth3 = serializeImage(clothingOptions3);
export const cloth4 = serializeImage(clothingOptions4);

export const pres1 = serializeImage(presentation1);
export const pres2 = serializeImage(presentation2);
export const pres3 = serializeImage(presentation3);
export const pres4 = serializeImage(presentation4);

export const downArrow = displaySvgIcon("down-arrow");
export const arrow = serializeImage(downArrow);