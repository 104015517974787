import React, { useEffect, useState } from "react";
// Import react-circular-progressbar module and styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useSelector } from "react-redux";

/**
 * CircularProgressIndicator tracks the recording time.
 * IMPORTANT: It is totaly dependent on the circular-progress-percent
 * set by MicrophoneSpeakTimer
 * @param {*} param0 
 * @returns 
 */
const CircularProgressIndicator2 = ({
  classLabel,
  defaultValue,
  isDataUploaded,
}) => {
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = scenarioData.global;
  const currentActivityType = globalData["current-activity-type"];

  let circularProgressPercentage;
  const passageProgress = scenarioData.passage["circular-progress-percentage"];
  const adviceProgress = scenarioData.advice["circular-progress-percentage"];
  const chatProgress = scenarioData.chat["circular-progress-percentage"];

  if (currentActivityType === "passage") {
    circularProgressPercentage = passageProgress;
  } else if (currentActivityType === "advice") {
    circularProgressPercentage = adviceProgress;
  } else if (currentActivityType === "chat") {
    circularProgressPercentage = chatProgress;
  }

  // console.log("CircularProgressIndicator: CircularProgressPercentage:", circularProgressPercentage);
  const [value, setValue] = useState(defaultValue);

  //   console.log("Value0:", value);
  useEffect(() => {
    // console.log("CircularProgressIndicator2:", circularProgressPercentage);
    setValue(circularProgressPercentage);
  }, [circularProgressPercentage]);

  useEffect(() => {
    // console.log("Value0:", value);
  }, [value]);

  return (
    <div
       className={"featuredChart"}
    >
      
      <CircularProgressbar 
      className={classLabel}
      value={value} 
      // text={"70%"} 
      strokeWidth={5}
      styles={buildStyles({
        strokeLinecap: "butt",
        // pathColor: `rgba(62, 152, 199, ${50 / 100})`,
        pathColor: `rgb(117, 33, 100)`,
        trailColor: "#dee1e0",
        backgroundColor: `transparent`,
        width: "100%",
        maxWidth: "118px",
        minWidth: "50px",
        minHeight: "50px",
      })}
      />
      </div>
  );
};

export default CircularProgressIndicator2;
