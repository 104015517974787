import React, { useState, useEffect, useRef } from "react";
import { notify } from "../../../utils/notify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
import { ToastContainer } from "react-toastify";
import WithRecording from "../global/hoc/GenericBaseRecording";
import CountDownTimer from "../../../global/outputs/CountDownTimer";
import { useDispatch, useSelector } from "react-redux";
import { getTimestamp } from "../../../utils/getTimeStamp";
import { storage } from "../../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import SoundwaveTransition from "../../../global/widgets/SoundwaveTransition";
import {
  updateGlobalValue,
  updateNextButtonStatus,
  updateMultipleGlobal,
} from "../../../redux/slices/scenarioSlice";
import ActiveMicrophone2 from "../../../global/widgets/ActiveMicrophone2";
import CircularProgressIndicator2 from "../../../global/widgets/CircularProgressIndicator2";
import { uploadToFirebase } from "../../../utils/firebase";

const PassageMap = {
  1: "passage-select-language",
  2: "passage-instructions",
  3: "passage-airport",
  4: "passage-train-station",
  5: "passage-james-gum",
  6: "passage-shark-teeth",
  7: "passage-restaurant",
};

const PassageEnglish = ({
  nextBtnRef,
  isRecording,
  setIsRecording,
  passageData,
  activityCountdownTimerComplete,
  setActivityCountdownTimerComplete,
  isSilentRef,
  isSilentForFiveSeconds,
  isTalkingForFiveSeconds,
  toggle,
  type,
  startRecording,
  stopRecording,
  setChatHistory,
  wordDetectedRef,
  startedTalking,
}) => {
  const dispatch = useDispatch();

  /**************************************************
   * Does nothing here: It is used to clock the CircularProgressbar
   **************************************************/
  const [microphoneSpeakTimeComplete, setMicrophoneSpeakTimeComplete] =
    useState(false);

  /**************************************************
   * REDUX
   ***************************************************/
  const scenarioData = useSelector((state) => state.scenarios);
  const translationData = scenarioData.passage;
  const currentScenario = translationData.shiftedItem;
  const passageTranslationData = scenarioData.passage;

  const shiftedAudioIndex = passageTranslationData.shiftedAudioIndex;
  console.log("shiftedAudioIndex", shiftedAudioIndex);

  const viewsData = passageTranslationData.views;
  const passageEnglishData = viewsData["passage-english"];
  const durationMessage = passageEnglishData["recording-time-message"];
  const currentLayout = scenarioData.global["current-layout"];
  const currentActivityType = scenarioData.global["current-activity-type"];
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const passageActivityTypeComplete = useSelector(
    (state) => state.scenarios.passage["complete"]
  );

  const globalData = useSelector((state) => state.scenarios.global);
  const nextBtnDisabled = globalData["nextBtnDisabled"];
  const userId = globalData.id;
  const activityTypes = useSelector(
    (state) => state.scenarios.global["page-names"]
  );
  const subviews = scenarioData.global.subviews;
  const pageSubviews = subviews[currentActivityType];
  const shiftedAudioComplete = passageTranslationData.shiftedAudioComplete;
  console.log("shiftedAudioComplete:1", shiftedAudioComplete);
  const nativeLanguage = passageTranslationData["native-language"];
  const meta = nativeLanguage + "-" + PassageMap[shiftedAudioIndex];
  console.log("meta", meta);
  console.log("nativeLanguage", nativeLanguage);

  /*************************************************
   *  FIREBASE FILE LINKS
   ***************************************************/
  const [fileLinks, setFileLinks] = useState([]);
  const [wordDetectionReset, setWordDetectionReset] = useState(false);
  /****************************************************
   * REFS
   ****************************************************/
  const isRecordingRef = useRef(isRecording);
  // What to do when there is an update to silence
  useEffect(() => {
    isSilentRef.current = isSilentForFiveSeconds;
  }, [isSilentForFiveSeconds]);
  const lastNotificationCheckRef = useRef(Date.now());
  /****************************************************/
  // CONSTANTS
  const typeNumber = 1;
  // const meta = "passage-translation";
  const passageCompleteNoAudioRecorded =
    passageData && passageActivityTypeComplete
      ? false
      : passageActivityTypeComplete;

  const passageAudioFileLength =
    passageTranslationData["audio-translations"]["english"]?.audios.length;
  console.log("PassageAudioLength2:", passageAudioFileLength);
  // const passageByLanguageEndingIndex = 7;
  const passageByLanguageEndingIndex = passageAudioFileLength + 2;

  /********************************************************
   * FUNCTIONS
   *********************************************************/
  /**************************
   * DEPRECATED by handleUpload: Upload files to Firebase
   **************************/
  const uploadFiles = (data) => {
    console.log("MyData:", data);
    if (data === null) return;
    const fileRef = ref(
      storage,
      `pte-a/${getTimestamp()}-${userId}-Type1-Text`
    );

    const audioRef = ref(
      storage,
      `pte-a/${getTimestamp()}-${userId}-Type1-Audio`
    );

    // const blobArray = [data.audioBlob, ];
    uploadBytes(fileRef, data.textBlob).then((snapshot) => {
      console.log("Uploaded Text!");
    });
    uploadBytes(audioRef, data.audioBlob).then((snapshot) => {
      console.log("Uploaded Audio!");
      // setIsDataUploaded(true);
    });
  };
  /****************************
   * Upload to Firebase
   ****************************/
  const handleUpload = async () => {
    // try sending the files from here instead of step 3:
    // uploadFiles(passageData);
    if (passageData) {
      // Check if passageData is available
      const response = await uploadToFirebase({
        passageData,
        userId,
        setIsDataUploaded,
        typeNumber,
        ...(meta ? { meta: meta } : {}),
      });
      console.log(response); // Optional: Handle the response if needed
    }

    console.log("passageActivityTypeComplete:", passageActivityTypeComplete);
    /*************************************
     * Reset current-view and current-page, once
     * data has been uploaded
     *************************************/
    const currentActivityTypeIndex = activityTypes.indexOf(currentActivityType);
    const nextPageIndex = currentActivityTypeIndex + 1;
    const nextPageName = activityTypes[nextPageIndex];
    console.log(
      "NextPageName:",
      nextPageName,
      "currentActivityType:",
      currentActivityType
    );

    console.log(
      "CHANGEING VIEW:",
      pageSubviews[0],
      "ShiftedAudioIndex:",
      shiftedAudioIndex
    );
    // (2) Transiton to the next type
    // dispatch(
    //   updateGlobalValue({
    //     key: "current-activity-type",
    //     value: nextPageName,
    //   })
    // );
    // dispatch(
    //   updateGlobalValue({
    //     key: "current-layout",
    //     value: subviews[nextPageName][0],
    //   })
    // );
    if (shiftedAudioIndex === passageByLanguageEndingIndex)
      dispatch(
        updateMultipleGlobal({
          "current-activity-type": nextPageName,
          "current-layout": subviews[nextPageName][0],
          // "current-stepper-segment": "passage-foreign",
        })
      );
  };

  /****************************************************
   *  Step 1: Program Entry point:
   * (1) On initial Render, after activityCountdownTimerComplete,
   *  set isRecording to true.
   * (2) For subsequent render, if  wordDetected.current true, then
   * enable nextBtnDisabled
   * (3) For subsequent render, if wordDetected.current false, then
   * set isRecording false (reset wordDetected.current == null,
   * setChatHistory([])), and notify user to speak louder
   ****************************************************/
  useEffect(() => {
    if (activityCountdownTimerComplete && !microphoneSpeakTimeComplete) {
      console.log(
        "3: Starting up Recording, not recording and word not detected: wordDetectecRef:",
        wordDetectedRef.current,
        "isRecording:",
        isRecording,
        "isTalkingForFiveSeconds:",
        isTalkingForFiveSeconds,
        "type",
        type,
        "isSilentForFiveSeconds:",
        isSilentForFiveSeconds,
        "isSilentRef:",
        isSilentRef.current,
        "startedTalking:",
        startedTalking
      );
      //  (1) Start recording on load for first silence detection
      if (
        !isRecording &&
        wordDetectedRef.current !== true
        //  && (isTalkingForFiveSeconds === true || startedTalking === true)
        // && type==="silent")
        // && ((isSilentRef.current === isSilentForFiveSeconds) && (startedTalking === !isSilentForFiveSeconds ))
      ) {
        // Start Detecting sound
        /*****************/
        console.log(
          "0: Starting up Recording, not recording and word not detected: wordDetectecRef:",
          wordDetectedRef.current,
          "isRecording:",
          isRecording,
          "isTalkingForFiveSeconds:",
          isTalkingForFiveSeconds,
          "type",
          type,
          "isSilentForFiveSeconds:",
          isSilentForFiveSeconds,
          "isSilentRef:",
          isSilentRef.current,
          "startedTalking:",
          startedTalking
        );
        setIsRecording(true);
      }
      // (3) Error State: if word not detected, set isRecording to false
      if (
        wordDetectedRef.current === false &&
        isRecording &&
        isTalkingForFiveSeconds === true
      ) {
        console.log("Word not detected, setting isRecording to false");
        console.log(
          "1: Starting up Recording, not recording and word not detected: wordDetectecRef:",
          wordDetectedRef.current,
          "isRecording:",
          isRecording,
          "isTalkingForFiveSeconds:",
          isTalkingForFiveSeconds,
          "type",
          type,
          "isSilentForFiveSeconds:",
          isSilentForFiveSeconds,
          "isSilentRef:",
          isSilentRef.current,
          "startedTaliking:",
          startedTalking
        );
        setIsRecording(false);
        wordDetectedRef.current = null;
        setChatHistory([]);
        setWordDetectionReset(true);
      }
    }
  }, [activityCountdownTimerComplete, wordDetectedRef.current, type]);

  /*********************************************************
   * Step (1B): Start Loop Cycle:  If wordDetection has been reset to null:
   * (1) Reset wordDetectedReset to false
   * (2) Set isRecording to true
   * (3) Notify user to speak loader
   * ******************************************************/
  useEffect(() => {
    const checkCondition = () => {
      if (wordDetectionReset && wordDetectedRef.current === null) {
        // (1): Reset wordDetectedReset to false
        setWordDetectionReset((prev) => {
          const newValue = !prev;
          console.log("WordDetectionReset:", newValue);
          // Directly set isRecording to true without waiting for a rerender
          // (2): Set isRecording to true
          setIsRecording(true);
          return newValue; // Return the updated state for setWordDetectionReset
        });

        // (3) Notify user to speak loader
        notify();
        // Update the timestamp to the current time
        lastNotificationCheckRef.current = Date.now();
      }
    };
    // Calculate the time elapsed since the last check
    const timeSinceLastCheck = Date.now() - lastNotificationCheckRef.current;

    // If the last check was more than 5 seconds ago, run checkCondition
    if (timeSinceLastCheck >= 5000) {
      checkCondition();
    }
  }, [wordDetectionReset]);

  /***************************************
   * Step1C: Execute Recording
   * (1) Start Recording when isRecording true
   * (2) Stop recording when isRecording false
   *****************************************/
  useEffect(() => {
    isRecordingRef.current = isRecording;
    console.log("USEEFFECT isRecording:", isRecording);
    /****************************************
     * Start Recording after the timer is complete
     * Else Stop Recording
     * ***************************************/
    if (isRecording && activityCountdownTimerComplete) {
      console.log(
        "StartingRecording isRecording:",
        isRecording,
        "activityCountdownTimerComplete:",
        activityCountdownTimerComplete
      );
      startRecording(null, null, "spoken", null, "passage", null);
    } else {
      console.log("StoppingRecording");
      stopRecording();
    }
  }, [isRecording, activityCountdownTimerComplete]);

  /*********************************************************
   * Step 2: Start transcribing Audio:
   * (1) Once a  word is detected, monitor for five seconds of silence,
   * (2) Toggle isSilentRef.current to false, so we flag the sound 
   * segment (soundDetected) in BaseRecording
      (3) If five seconds of silence found, set isRecording stop recording.
   *********************************************************/
  useEffect(() => {
    // (1): Monitor for five seconds of silence
    if (
      wordDetectedRef.current === true &&
      isRecording &&
      isSilentForFiveSeconds &&
      type === "silent"
    ) {
      console.log(
        "wordDetectedRef:",
        wordDetectedRef.current,
        "IsRecording:",
        isRecording,
        " isSilentForFiveSeconds:",
        isSilentForFiveSeconds,
        "type:",
        type
      );
      // (2) Toggle isSilentRef.current to false, so we flag sound segment
      isSilentRef.current = false;

      // (3) Set isRecording to false
      setIsRecording(false);
    }
  }, [wordDetectedRef.current, isSilentForFiveSeconds, toggle, type]);

  /*************************************************
   * Step 3: Detect if the Passage was sent on button click
   * (1) If passageData recieved, upload it
   * (2) ElseIf passageData recieved, set isDataUploaded to true
   * so it can be uploaded in next step (performance improvement)
   * (2B) Enabel the submit button
   * Once button is enabled, transition to next
   * type
   **************************************************/
  useEffect(() => {
    const passageCompleteAudioRecorded =
      passageData?.transcripts[0].content !== null &&
      passageActivityTypeComplete;
    console.log(
      "passageCompleteAudioRecorded:",
      passageCompleteAudioRecorded,
      "isDataUploaded:",
      isDataUploaded,
      "passageData:",
      "isRecording:",
      isRecording,
      passageData?.transcripts
    );
    // (1) Upload passageData to Firebase
    if (
      !passageCompleteAudioRecorded &&
      !isDataUploaded &&
      !isRecording &&
      passageData?.transcripts
    ) {
      console.log(
        "passageCompleteAudioRecorded:",
        passageCompleteAudioRecorded
      );
      /**************************************
       * (2) We will set IsDataUploaded to true,
       * and will enable the submit button shortly
       * after, so we don't wait for a page refresh
       **************************************/
      setIsDataUploaded((prev) => {
        const newValue = true;
        // (2B) Enable the submit button
        dispatch(
          updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
        );
        return newValue;
      });
    }
  }, [passageData, isDataUploaded]);

  /*****************************************
   * Step 4: Once activityType is complete:
   * (submit clicked)Upload the passageData:
   ***********This is more performant******
   * (1) uploaded the passageData
   * (2)Transition to the next Type2
   *****************************************/
  useEffect(() => {
    if (passageActivityTypeComplete) {
      handleUpload();
    }
  }, [passageActivityTypeComplete]);

  // Upload the scenarios before the last item:
  useEffect(() => {
    console.log("$shiftedAudioComplete:", shiftedAudioComplete);
    if (shiftedAudioComplete) {
      console.log("UploadingData");
      handleUpload();
      dispatch(
        updateMultipleGlobal({
          "current-activity-type": "passage",
          "current-layout": "passage-foreign",
          "current-stepper-segment": "passage-foreign",
        })
      );
    }
  }, [shiftedAudioComplete]);

  /*************************************************
   * Step 5: microphoneSpeakTimeComplete with no audio
   * (1) Enable button
   * (2) Click button
   ***************************************************/
  useEffect(() => {
    const passageDataHasNoAudio = passageData?.transcripts[0].content === null;
    console.log(
      "microphoneSpeakTimeComplete0:",
      microphoneSpeakTimeComplete,
      "passageDataHasNoAudio:",
      passageDataHasNoAudio
    );
    if (microphoneSpeakTimeComplete) {
      console.log(
        "microphoneSpeakTimeComplete1:",
        microphoneSpeakTimeComplete,
        "passageDataHasNoAudio:",
        passageDataHasNoAudio
      );
      // (1) Enable button
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
      // (2) Re-run effect after state update to trigger button Click
      if (!nextBtnDisabled) {
        console.log("NextBtn:", nextBtnRef.current);
        nextBtnRef.current.click();
      }
    }
  }, [microphoneSpeakTimeComplete, nextBtnDisabled, dispatch]);
  /*****************************************************/

  return (
    <>
      <div className="column">
        <div className="listen-countdown">
          <div className="countdown">
            <div className="text">Now, retell the passage in English.</div>
            {!activityCountdownTimerComplete ? (
              <CountDownTimer
                isTimerComplete={activityCountdownTimerComplete}
                setIsTimerComplete={setActivityCountdownTimerComplete}
                stepperLabel="translation-countdown"
                disableCircularProgressbar={true}
              />
            ) : (
              <span class="text duration">{durationMessage}</span>
            )}
          </div>
          <div className="speaker">
            {/* MOVED TO THE END */}
            <SoundwaveTransition
              isTimerComplete={activityCountdownTimerComplete}
              //   audioPlayed={audioPlayed}
              classLabel="system-response"
            />
            {/* {console.log("AUDIO TYPE:", type)} */}
          </div>
          {/* Microphones WIth CircularProgressIndicator */}
          <div className="microphone-container">
            {activityCountdownTimerComplete ? (
              <CircularProgressIndicator2
                classLabel="time-test"
                defaultValue={0}
                slice="passage"
              // isDataUploaded={isDataUploaded}
              />
            ) : null}
            <ActiveMicrophone2
              isTimerComplete={activityCountdownTimerComplete}
              type={type}
              isDataUploaded={isDataUploaded}
              setSpeakTimeComplete={setMicrophoneSpeakTimeComplete}
              // recordingDuration={90}
              recordingDuration={120}
              recordingUnit="seconds"
              stepperLabel="passage-english"
            />
          </div>
        </div>
      </div>
      <div>
        <button className="hide" onClick={notify} id="animate.css">
          Notify !
        </button>
        <ToastContainer
          limit={1}
          autoClose={6000}
          className="toast-container"
        />
      </div>
    </>
  );
};

export default WithRecording(PassageEnglish);
