import React from "react";
import BlockImageContainer from "./BlockImageContainer";
import displaySvgIcon from "../utils/displaySvgIcon";

/**
 * RowContainer component to render scenarios with images grouped in block containers.
 * This component iterates over the rows in the multi-dimensional array
 * and renders a BlockContainer for each row, with special elements before the first and second rows.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.currentScenario - The current scenario object containing images.
 * @returns {JSX.Element} The rendered list of BlockContainers with special elements before the first and second rows.
 */
const RowContainer = ({ currentScenario, originalLabel, newLabel }) => {
  const images = currentScenario?.images;

  const displaySVG = () => {
    return <>{displaySvgIcon("down-arrow")}</>;
  };

  // Map over the rows in the 2D array and render the appropriate content for each row
  const displayImages = images?.flatMap((row, rowIndex) => {
    // Initialize elements array for the current row
    const elements = [];

    // Add the appropriate elements based on the rowIndex
    if (rowIndex === 0 || rowIndex === 3) {
      // Image row
      const labels = images[rowIndex + 1]; // Get the corresponding labels for the next row
      elements.push(
        <div key={`row-${rowIndex}`} className={`row-${rowIndex}`}>
          <BlockImageContainer images={row} labels={labels} />
        </div>
      );
    } else if (rowIndex === 2) {
      // SVG row
      elements.push(
        <div key={`svg-icon-${rowIndex}`} className="svg-icon">
          {displaySVG()}
        </div>
      );
    }

    // Insert "ORIGINAL PLAN" at the beginning
    if (rowIndex === 0) {
      elements.splice(0, 0, (
        <div key="original-plan" className="original-container plan">
          {originalLabel}
        </div>
      ));
    }

    // Insert "NEW PLAN" at the 5th index after elements have been loaded
    if (rowIndex === 3) {
      elements.splice(0, 0, (
        <div key="new-plan" className="new-container plan gp-70">
          {newLabel}
        </div>
      ));
    }

    return elements;
  });

  // Wrap the first two rows and the last two rows
  const wrappedDisplayImages = (
    <>
      <div className="original">
        {displayImages?.slice(0, 2)}
      </div>
      {displayImages && displayImages[2]} {/* SVG row */}
      <div className="new">
        {displayImages && displayImages.slice(3)}
      </div>
    </>
  );

  return <>{wrappedDisplayImages}</>;
};

export default RowContainer;
