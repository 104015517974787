import React from "react";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const SvgWrapper = styled.svg`
  width: 200px;
  height: 200px;
  position: relative;
`;

const OuterRing = styled.rect`
  animation: ${pulse} 2s infinite;
  transform-origin: center;
`;

const InnerContentWrapper = styled.g`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContent = styled.g`
  width: 118px;
  height: 118px;
`;

const AnimatedSVG = ({ classLabel }) => {
  return (
    <div className={classLabel}>
      {/* SVG code */}
      <SvgWrapper
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // style={{ flexShrink: 1, width: "100%" }}
        className="silent"
      >
        <OuterRing
          x="12"
          y="12"
          width="176"
          height="176"
          rx="88"
          fill="#F1DFEA"
          fillOpacity="0.69"
        />
        <InnerContentWrapper>
          <InnerContent>
            <rect
              x="41"
              y="41"
              width="118"
              height="118"
              rx="59"
              fill="#FAFAFA"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M111 101V82C111 75.3726 106.627 70 100 70C93.3726 70 89 75.3726 89 82V101C89 107.627 93.3726 112 100 112C106.627 112 111 107.627 111 101ZM100 66C91.7157 66 85 72.7157 85 82V101C85 109.284 91.7157 116 100 116C108.284 116 115 109.284 115 101V82C115 72.7157 108.284 66 100 66ZM79 97C80.1046 97 81 97.8954 81 99V101C81 111.493 90.5066 120 100 120C109.493 120 119 111.493 119 101V99C119 97.8954 119.895 97 121 97C122.105 97 123 97.8954 123 99V101C123 113.15 113.574 123.154 102 124.269V132H110C111.105 132 112 132.895 112 134C112 135.105 111.105 136 110 136H100H90C88.8954 136 88 135.105 88 134C88 132.895 88.8954 132 90 132H98V124.333C85.8457 123.623 77 114.775 77 101V99C77 97.8954 77.8954 97 79 97Z"
              fill="#722362"
            />
          </InnerContent>
        </InnerContentWrapper>
      </SvgWrapper>
    </div>
  );
};

export default AnimatedSVG;
