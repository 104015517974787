import React, { useState, useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { useSelector, useDispatch } from "react-redux";

// import "./Stepper.css"; // Import the CSS file

const Stepper = () => {
  const scenarioData = useSelector((state) => state.scenarios);
  const passageData = scenarioData.passage;
  const globalData = scenarioData.global;
  const currentLayout = globalData["current-layout"];
  const currentActivityType = globalData["current-activity-type"];
  // console.log("Current Page0:", currentActivityType, "View:", currentLayout);
  let currentStepperSegment = globalData["current-stepper-segment"];

  // const viewNames = useSelector(
  //   (state) => state.scenarios.global["view-names"]);

  /******/
  const stepperSubviews = scenarioData.global.subviews;
  let segmentsByActivity = stepperSubviews[currentActivityType];
  let numberOfSegmentsByPage = currentActivityType === "passage" ? segmentsByActivity.length + 2 : segmentsByActivity.length;
  console.log(
    "Stepper Segmentation: NumberOfSegmentsByPage:",
    numberOfSegmentsByPage,
    "currentLayout:",
    currentLayout,
    "currentActivityType:",
    currentActivityType,
    "segmentsByActivity:",
    segmentsByActivity,
    "CurrentStepperSegment:",
    currentStepperSegment
  );
  /*******/
  // console.log("Pages:", viewNames);
  // const segmentLength = viewNames.length;

  let segmentLength = numberOfSegmentsByPage;
  let pagePercentages = 100 / segmentLength;
  let stepPercentage =
    segmentsByActivity.indexOf(currentStepperSegment) > -1
      ? segmentsByActivity.indexOf(currentStepperSegment) * pagePercentages
      : 0;

      // 8/1/2024 Passage Stepper Update: Now works based on scenarioData.passage.shiftedAudioIndexes
  if (currentActivityType === "passage") {
    currentStepperSegment = passageData.shiftedAudioIndex;
    segmentsByActivity = passageData.shiftedAudioIndexes;
    numberOfSegmentsByPage = segmentsByActivity.length;
    console.log(
      "Passage: Stepper Segmentation: NumberOfSegmentsByPage:",
      numberOfSegmentsByPage,
      "currentLayout:",
      currentLayout,
      "currentActivityType:",
      currentActivityType,
      "segmentsByActivity:",
      segmentsByActivity,
      "CurrentStepperSegment:",
      currentStepperSegment
    );

    segmentLength = numberOfSegmentsByPage;
    pagePercentages = 100 / segmentLength;
    stepPercentage =
      segmentsByActivity.indexOf(currentStepperSegment) > -1
        ? segmentsByActivity.indexOf(currentStepperSegment) * pagePercentages
        : 0;
  }

  useEffect(() => {
    console.log(
      "STEPPER:currentStepperSegment:",
      currentStepperSegment,
      "stepPercentage:",
      stepPercentage
    );
  }, [currentStepperSegment]);

  return (
    <div className="progress-bar">
      <ProgressBar percent={stepPercentage}>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            ></div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            >
              {/* {index + 1} */}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            ></div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            ></div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
            ></div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default Stepper;
