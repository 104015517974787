import React from 'react';

// import '../../assets/styles/Sass/styles.scss';
const Button = ({ pointer,buttonClass, image, label, variant, size, disabled, ...rest }) => {
	return (
		<button
		ref={pointer}
			className={`${buttonClass}`}
			{...rest}
			disabled={disabled}
		>
			{ buttonClass === "stepper-btn" ? label : (
				<>
				{image ? <span>{image} &nbsp;</span> : null}
				<span>{label}</span>
				</>)}
		</button>
	);
};

export default Button;