export const playWavBlob = async (wavFilePath) => {
    try {
      // Fetch the .wav file as an ArrayBuffer
      const response = await fetch(wavFilePath);
      const arrayBuffer = await response.arrayBuffer();
  
      // Convert ArrayBuffer to a Blob with MIME type 'audio/wav'
      const audioBlob = new Blob([arrayBuffer], { type: 'audio/wav' });
  
      // Create an audio object from the Blob URL
      const audio = new Audio(URL.createObjectURL(audioBlob));
  
      // Play the audio
      audio.play();
  
      // Listen for when the audio finishes playing
      return new Promise((resolve) => {
        audio.onended = () => {
          console.log("Audio finished playing.");
          resolve(true);  // Return true when playback completes
        };
      });
    } catch (error) {
      console.error("Error playing audio:", error);
      throw error;  // Throw the error to be handled by the caller
    }
  };
  