import React, {memo} from "react";



const Translation = ({ children}) => {
  console.log("Translation re-rendered");
  return (
    <div className="page">
        {children}
    </div>
  );
};

export default memo(Translation);
