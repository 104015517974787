export const ensureMinimumAudioLength = async (audioBlob, minLengthSeconds = 0.1) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const originalArrayBuffer = await audioBlob.arrayBuffer();
    const originalAudioBuffer = await audioContext.decodeAudioData(originalArrayBuffer);
  
    if (originalAudioBuffer.duration >= minLengthSeconds) {
      // No need to add silence
      return audioBlob;
    }
  
    // Add silence to meet the minimum duration
    const minLength = minLengthSeconds * audioContext.sampleRate;
    const silenceBuffer = audioContext.createBuffer(
      originalAudioBuffer.numberOfChannels,
      minLength - originalAudioBuffer.length,
      audioContext.sampleRate
    );
  
    // Fill the silence buffer with zeros
    for (let i = 0; i < silenceBuffer.numberOfChannels; i++) {
      const channelData = silenceBuffer.getChannelData(i);
      channelData.fill(0);
    }
  
    // Create a new buffer with the original audio and silence
    const finalBuffer = audioContext.createBuffer(
      originalAudioBuffer.numberOfChannels,
      originalAudioBuffer.length + silenceBuffer.length,
      audioContext.sampleRate
    );
  
    // Copy original audio data to the new buffer
    for (let i = 0; i < originalAudioBuffer.numberOfChannels; i++) {
      finalBuffer.copyToChannel(originalAudioBuffer.getChannelData(i), i, 0);
    }
  
    // Copy silence data to the new buffer
    for (let i = 0; i < silenceBuffer.numberOfChannels; i++) {
      finalBuffer.copyToChannel(silenceBuffer.getChannelData(i), i, originalAudioBuffer.length);
    }
  
    // Convert the final buffer to a Blob and return it
    return bufferToWave(finalBuffer);
  };
  
  // Helper function to convert an AudioBuffer to a WAV Blob
  const bufferToWave = (buffer) => {
    const numOfChan = buffer.numberOfChannels,
      length = buffer.length * numOfChan * 2 + 44,
      bufferArray = new ArrayBuffer(length),
      view = new DataView(bufferArray),
      channels = [],
      sampleRate = buffer.sampleRate;
  
    let offset = 0,
      pos = 0;
  
    // write WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"
  
    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(sampleRate);
    setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this example)
  
    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length
  
    // write interleaved data
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = buffer.getChannelData(channel)[i] * 32767;
        if (sample < -32768) sample = -32768;
        if (sample > 32767) sample = 32767;
        view.setInt16(pos, sample, true);
        pos += 2;
      }
    }
  
    return new Blob([view], { type: 'audio/wav' });
  
    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }
  
    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  };
  