import { createSlice } from "@reduxjs/toolkit";
import {
  setNativeLanguageReducer,
  getScenarioReducer,
  fetchAsyncTranslation,
  updateSilenceReducer,
  updateTranslationReducer,
  updateGlobalReducer,
  updateMultipleGlobalReducer,
  updateNextBtnStatusReducer,
  setUserReducer,
  updateAdviceReducer,
  updateCompleteScenarioByTypeReducer,
  clearAdviceScenarioShiftedItemReducer,
  updateKeyByActivityReducer
} from "../reducers/scenarioReducer";

// import navbarData from "../data/navbarData";
import scenarioData from "../data/scenarioData";

const initialState = {
  ...scenarioData,
  advice: {
    ...scenarioData.advice,
    scenarios_copy: [...scenarioData.advice.scenarios],
  },
  chat: {
    ...scenarioData.chat,
    scenarios_copy: [...scenarioData.chat.scenarios],
  },
  "chat-written": {
    ...scenarioData["chat-written"],
    scenarios_copy: [...scenarioData["chat-written"].scenarios],
  },
  opinion: {
    ...scenarioData.opinion,
    scenarios_copy: [...scenarioData.opinion.scenarios],
  },
};

/**
 * Serializes images to ensure they are in a string format.
 * @param {Array} images - A 2D array of images.
 * @returns {Array} - A 2D array of serialized image URLs.
 */
const serializeImages = (images) => {
  return images?.map(row => row?.map(image => typeof image === 'string' ? image : image.default || image.src || image));
};

/********************************
 * Configure Slice with reducers
 * and Export actions and reducer
 *******************************/
const scenarioSlice = createSlice({
  name: "scenarios",
  initialState,
  reducers: {
    setNativeLanguage: setNativeLanguageReducer,
    getScenario: getScenarioReducer,
    updateSilence: updateSilenceReducer,
    updateTranslationValue: updateTranslationReducer,
    updateAdviceValue: updateAdviceReducer,
    updateCompleteScenarioByType: updateCompleteScenarioByTypeReducer,
    updateKeyByActivity: updateKeyByActivityReducer,
    updateGlobalValue: updateGlobalReducer,
    updateMultipleGlobal: updateMultipleGlobalReducer,
    updateNextButtonStatus: updateNextBtnStatusReducer,
    setUser: setUserReducer,

    /**
     * Removes the first item from the scenarios_copy array for the specified activity,
     * updates the shiftedItem, and increments the shiftedItemIndex.
     * @param {Object} state - The current state.
     * @param {Object} action - The action containing the payload.
     */
    removeActivityScenariosItem: (state, action) => {
      const activity = action.payload;
      if (state[activity].scenarios_copy.length > 0) {
        const shiftedItem = { ...state[activity].scenarios_copy[0], completed: false };
        const newScenariosCopy = state[activity].scenarios_copy.slice(1);

        state[activity] = {
          ...state[activity],
          scenarios_copy: newScenariosCopy,
          shiftedItem: shiftedItem,
          shiftedItemIndex: state[activity].shiftedItemIndex + 1,
        };
      }
    },
    
     /**
     * Removes the first item from the scenarios_copy array for the specified activity,
     * updates the shiftedItem, and increments the shiftedItemIndex.
     * @param {Object} state - The current state.
     * @param {Object} action - The action containing the payload.
     */
    //  removePassageAudioItem: (state, action) => {
    //   const activity = action.payload;
    //   if (state[activity].translations_copy.length > 0) {
    //     const shiftedAudio = state[activity].translations_copy[0];
    //     console.log("shiftedAudio", shiftedAudio);
    //     const newTranslationsCopy = state[activity].translations_copy.slice(1);
    //     console.log("newTranslationsCopy", newTranslationsCopy);
    //     state[activity] = {
    //       ...state[activity],
    //       translations_copy: newTranslationsCopy,
    //       shiftedAudio: shiftedAudio,
    //       shiftedAudioIndex: state[activity].shiftedAudioIndex + 1,
    //     };
    //   }
    // },
    removePassageAudioItem: (state, action) => {
      const activity = action.payload;
    
      if (state[activity].translations_copy.length > 0) {
        // Shift the audio item from the translations_copy array
        const shiftedAudio = state[activity].translations_copy[0];
        const newTranslationsCopy = state[activity].translations_copy.slice(1);
    
        // Increment the shiftedAudioIndex
        const newShiftedAudioIndex = state[activity].shiftedAudioIndex + 1;
    
        // Update the state
        state[activity] = {
          ...state[activity],
          translations_copy: newTranslationsCopy,
          shiftedAudio: shiftedAudio,
          shiftedAudioIndex: newShiftedAudioIndex,
        };
    
        // Array of views that need their footer label updated
        const viewsToUpdate = [
          // "language-select",
          // "instructions",
          "language-countdown",
          "passage-foreign",
          "translation-countdown",
          "passage-english",
        ];
    
        // Update the label in each of the specified views
        viewsToUpdate.forEach(view => {
          if (state[activity].views[view]?.footer) {
            state[activity].views[view].footer.label = `${newShiftedAudioIndex} of 7`;
          }
        });
      }
    },
    
    

    /**
     * Resets the scenarios_copy array for the advice activity to its initial state.
     * @param {Object} state - The current state.
     */
    resetAdviceScenariosCopy: (state) => {
      state.advice = {
        ...state.advice,
        scenarios_copy: [...initialState.advice.scenarios]
      };
    },
    // setUserDisciplines: updateScenarioReducer,
  },
  extraReducers: (builder) => {
    /********************************************
     * 1) if fetchAsyncTranslation returns pending
     * update loading state only
     * 2) if fetchAsyncTranslation returns fulfuilled
     * then perform update and return state
     ********************************************/
    builder
      .addCase(fetchAsyncTranslation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAsyncTranslation.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("Payload:", action.payload);
        console.log("State:", state.pseudocode);
        state.passage.translations = action.payload;
      })
      .addCase(fetchAsyncTranslation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Note: scenarioSlice.actions automatically creates actions based on our reducer names
export const {
  setNativeLanguage,
  getScenario,
  updateSilence,
  updateTranslationValue,
  updateAdviceValue,
  // updateAdviceScenarioComplete,
  updateCompleteScenarioByType,
  updateKeyByActivity,
  toggleAdvicePage,
  updateGlobalValue,
  updateMultipleGlobal,
  updateNextButtonStatus,
  setUser,
  removePassageAudioItem,
  removeActivityScenariosItem,
  // clearAdviceScenarioShiftedItem,
  resetAdviceScenariosCopy,
  // setUserDisciplines
} = scenarioSlice.actions;

// export  reducer, so it can be added to the store
export default scenarioSlice.reducer;
