  import {uploadFiles} from "./uploadedFiles";

  export const uploadToFirebase = async ({
    passageData,
          userId,
          setIsDataUploaded,
          typeNumber,
          meta
  }) => {
      try {
        const uploadResponse = await uploadFiles(
          passageData,
          userId,
          setIsDataUploaded,
          typeNumber,
          meta
        );
        return "Upload Successful";
      } catch (error) {
        console.error("Error uploading data:", error);
      }
    };