import React, { useEffect, useRef, useState } from "react";

/**
 * ChatHistoryText component
 * This component renders a chat interface where the chat history is displayed along with an input field for the user to type and send messages.
 * It also shows a "Jamie is typing..." message when the user is typing, and removes it after 2 seconds of inactivity.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.chatHistory - Array of chat messages. Each message is an object with `content` and `role` keys.
 * @param {Function} props.setChatHistory - Function to update the chat history.
 * @param {Function} props.sendMessage - Function to handle sending a new message.
 * @param {Object} props.scenarioMetadata - Metadata related to the chat scenario.
 */

const ChatHistoryText = ({
  chatHistory,
  setChatHistory,
  sendMessage,
  scenarioMetadata,
  currentScenario
}) => {
  const chatEndRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const assistantObjectRef = useRef(null);
  const historyToRenderRef = useRef([]);
  const [delayedChatHistory, setDelayedChatHistory] = useState([]);
  const [prevChatHistoryLength, setPrevChatHistoryLength] = useState(
    chatHistory.length
  );

  console.log("ChatHistoryText: currentScenario:", currentScenario);
  // Handle user typing in the textarea
  const handleTyping = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);

    // Exit condition for 'Enter' Press
    if (e.key === "Enter" && !e.shiftKey) {
      const filteredMessage = chatHistory.filter((msg) => msg.content !== "Jamie is typing...")
      setChatHistory(filteredMessage);
      setIsSendingMessage(true);
      return;
    }
  }; // handleTypeing

  const handleNewMessage = (e) => {
    const newMessage = e.target.value;
    // console.log("newMessage:", newMessage);
    setMessage(newMessage);
  };

  // Handle chat display
  const renderChatHistory = () => {
    return delayedChatHistory.map((msg, index) => (
      <div
        key={index}
        className={
          msg.role === "indicator"
            ? "chat-message indicator"
            : `chat-message ${msg.role}`
        }
      >
        {/* {console.log("msg:", msg, "index:", index)} */}
        <div className={msg.role === "indicator" ? "chat-text" : "chat-bubble"}>
          {msg.content}
        </div>
      </div>
    ));
  };

/***********************************************************
 * Responsible for pop new system messages, displaying the typing 
 * indicator Pushing message back in and then displaying it after
 ***********************************************************/
  useEffect(() => {
    assistantObjectRef.current = chatHistory[chatHistory.length - 1];
    // console.log("b4: chatHistory:", chatHistory);
    // console.log("b4: prevChatHistoryLength:", prevChatHistoryLength);
    // console.log("b4: chatHistory.length:", chatHistory.length);
    // console.log("b4: assistantObjectRef.current:", assistantObjectRef.current);
    // console.log("b4: historyToRenderRef.current:", historyToRenderRef.current);
   
    const chatHistoryLength = chatHistory.length;
    const assistantInitialOrAssistantAndUserResponseReceived =
      chatHistoryLength % 2 !== 0;

    if (
      chatHistoryLength > prevChatHistoryLength &&
      assistantInitialOrAssistantAndUserResponseReceived
    ) {
      historyToRenderRef.current = chatHistory.slice(0, -1);

      // First, set a 1-second timeout to display the messages before the indicator
      setTimeout(() => {
        setDelayedChatHistory([...historyToRenderRef.current]);
        historyToRenderRef.current.push({
          content: currentScenario.notification,
          role: "indicator",
        });

        /**************************************************
         * Once response indicator is pushed in display the history
         * Check if the last object has role: "indicator" if it exists
         * display it.
         * ************************************************/
        // console.log("HISTORYTORENDERREF:", historyToRenderRef.current);
        const historyLength = historyToRenderRef.current.length;
        const historyContainsAssistantResponseMessage =
          historyToRenderRef.current[historyLength - 1].role === "indicator";
        // console.log(
        //   "after: assistanceObjectRef.current:",
        //   assistantObjectRef.current
        // );

        // console.log(
        //   "after: historyToRenderRef.current:",
        //   historyToRenderRef.current
        // );

        // console.log("after: chatHistory updated: chatHistory:", chatHistory);

        // First, set a 5-second timeout for the typing indicator
        setTimeout(() => {
          if (historyContainsAssistantResponseMessage) {
            setDelayedChatHistory([...historyToRenderRef.current]);

            // Then, set a 10-second timeout for the assistant's response
            setTimeout(() => {
              if (historyContainsAssistantResponseMessage) {
                historyToRenderRef.current.pop();
                setDelayedChatHistory([
                  ...historyToRenderRef.current,
                  assistantObjectRef.current,
                ]);
              }
            }, 5000); // 10 seconds
          }
        }, 2000); // 5 seconds
      }, 500);

      /******************************************************************* */
    } //if indicator is pushed
    setPrevChatHistoryLength(chatHistory.length);
  }, [chatHistory]);

  // Scroll to the end of the chat history whenever it updates
  useEffect(() => {
    console.log("DelayedChatHistory:", delayedChatHistory);
    // historyToRenderRef.current = delayedChatHistory;
    chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [delayedChatHistory]);

  useEffect(() => {
    console.log("In handleSendMessage useEffect");
    // Send blank message to trigger initalQuestion from assistant
    sendMessage(null);
    setMessage("");
    setIsSendingMessage(false);
  }, [scenarioMetadata]);

  useEffect(() => {
    // console.log(
    //   "isSendingMessage:",
    //   isSendingMessage
    // );

    if (isSendingMessage) {
      // console.log("sending the message:", message);
      sendMessage(message);
      setMessage("");
      setIsSendingMessage(false);
    }
  }, [isSendingMessage]);

  return (
    <div className="chat-container-text">
      <div className="chat-history">
        {renderChatHistory()}
        <div ref={chatEndRef} />
      </div>
      <form className="chat-input">
        <textarea
          value={message}
          onChange={handleNewMessage}
          placeholder="Type your message, then press Enter"
          onKeyUp={handleTyping}
        />
      </form>
    </div>
  );
};

export default ChatHistoryText;
