import {encodeWAV} from "./encodeWav.js";

/// Helper function to convert an AudioBuffer to a WAV Blob
export const outputAudioBlob = async (audioContext, audioBuffer) => {
  const offlineContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);
  const bufferSource = offlineContext.createBufferSource();
  bufferSource.buffer = audioBuffer;
  bufferSource.connect(offlineContext.destination);
  bufferSource.start();
  const renderedBuffer = await offlineContext.startRendering();
  
  const wavArrayBuffer = encodeWAV(renderedBuffer);
  return new Blob([wavArrayBuffer], { type: 'audio/wav' });
};