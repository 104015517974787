import React, { useState, useEffect, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import displaySvgIcon from "../../../utils/displaySvgIcon";
// import repeatingSpeaker from "../../assets/images/audio/repeating-speaker.gif";
import { playAudio } from "../../../utils/OpenAi";
import { playWavBlob } from "../../../utils/playWavBlob";
import {
  updateNextButtonStatus,
  updateGlobalValue,
  setNativeLanguage,
  removePassageAudioItem,
  updateMultipleGlobal,
  updateTranslationValue
} from "../../../redux/slices/scenarioSlice";
import SoundwaveTransition from "../../../global/widgets/SoundwaveTransition";
import CountDownTimer from "../../../global/outputs/CountDownTimer";
import passage1 from "../../../assets/audio/english/passage1_english.wav";

/**************************************************
 * Passage English
 ***************************************************/
import useCheckSilence from "../../../utils/hooks/useCheckSilence";
/*****************************************************/

const PassageForeign = () => {
  /**************************************
   * Redux State Variables
   **************************************/
  const dispatch = useDispatch();
  const translationData = useSelector(
    (state) => state.scenarios.passage.translations
  );
  const scenarioData = useSelector((state) => state.scenarios);
  // console.log("scenarioData:", scenarioData);
  const globalData = useSelector((state) => state.scenarios.global);
  const userId = globalData.id;
  const currentActivityType = scenarioData.global["current-activity-type"];
  // console.log("currentActivityType:", currentActivityType);
  const activityData = scenarioData[currentActivityType];
  // console.log("activityData:", activityData);
  const nativeLanguage = activityData["native-language"];
  // console.log("nativeLanguage:", nativeLanguage);
  // console.log("nativeLanguage:", nativeLanguage);

  const shiftedAudio = activityData.shiftedAudio;
  // console.log("shiftedAudio:", shiftedAudio);

  const translationsCopy = activityData.translations_copy;
  console.log("translationsCopy:", translationsCopy, "isArray:", Array.isArray(translationsCopy));
  /*************************************************
   * Passage English Variables
   **************************************************
   * Returns and object with the following keys:
   * audioBlob - Containing the audio data
   * textBlob - Containing the text data
   * transcripts - Containing transcript text.
   *************************************************/

  /*****************************************
   * REACT State Variables
   *****************************************/
  // Step1: Start CountDown Clock
  const [passageAudioCountdownComplete, setPassageAudioCountdownComplete] =
    useState(false);
  // audioPlayed indicates if the audio has been played or not
  const [audioPlayed, setAudioPlayed] = useState(false);
  // When true startSpeaking controls the soundwave animation
  // set to true when timer is complete (passageAudioCountdownComplete)
  const [startSpeaking, setStartSpeaking] = useState(false);
  /**********************************************
   * REFERENCES
   ***********************************************/
  const audioInitializedRef = useRef(false);
  /************************************************
   * FUNCTIONS
   ************************************************/
  // DEPRECATED by playAudio: Using Audio Files to Play Passage now:
  const handlePlayAudio = async (text) => {
    console.log("NEXTBTNDISABLED:", true);
    const result = await playAudio(text);
    if (result) {
      setAudioPlayed(result);
      console.log("Launching Play Audio");
      console.log("Audio finished playing successfully.");
    } else {
      console.log("An error occurred while playing the audio.");
    }
  };

  // Grab the audio files and store them in translations_copy
  const loadAudioFiles = (selectedLanguage) => {
    dispatch(setNativeLanguage(selectedLanguage));
  };

  /*************************************
   * Loads the individual scenario data from redux
   *************************************/
  const handleShiftScenario = () => {
    dispatch(removePassageAudioItem("passage"));
    // wordDetectedRef.current = null;
    // setWordDetectionReset(false);
  };

  // Play an audio .WAV file
  const playAudio = async (wavFile) => {
    // if (translationData && !audioPlayed && startSpeaking) {
      try {
        const result = await playWavBlob(wavFile);
        if (result) {
          console.log("Audio finished playing successfully.");
          // Perform additional actions if necessary
          setAudioPlayed(result);
        }
      } catch (error) {
        console.error("Audio playback failed:", error);
      }
    // }
  };
  /*************************************************
   * USEEFFECTS
   **************************************************/
  /**
   * Update shiftedAudioComplete whenever we submit 
   * translated passage data so the data can be loaded
   **/
  useEffect(() => {
    if (translationsCopy.length === 0 && nativeLanguage) {
      // Reset shiftedAudioComplete false
      dispatch(
        updateMultipleGlobal({
          "shiftedAudioComplete:": false,
        })
      );

      console.log("load translation_copy");
      loadAudioFiles(nativeLanguage);
    }
  }, [nativeLanguage]);
  
  /**
   * Reset shiftedAudioComplete to false when 
   * a new audio file is loaded and audioInitializedRef is false
   */
  useEffect(() => {
    if (translationsCopy.length > 0 && !audioInitializedRef.current) {
       // Reset shiftedAudioComplete false
      //  dispatch(
      //   updateMultipleGlobal({
      //     "shiftedAudioComplete:": false,
      //   })
      // );
      // Reset shiftedAudioComplete
      dispatch(updateTranslationValue({
        key: "shiftedAudioComplete",
        value: false,
      })
    );
      handleShiftScenario();
      audioInitializedRef.current = true;
    }
  }, [translationsCopy]);
  
  /**
   * Play the audio when audio file is loaded in shiftedAudio and startSpeaking is true
   **/
  useEffect(() => {
    console.log("shiftedAudio:", shiftedAudio, "length:", Object.keys(shiftedAudio).length);
    if (typeof shiftedAudio === "string"  && startSpeaking) {
      console.log("shiftedAudio:", shiftedAudio);
      playAudio(shiftedAudio);
    }
  // }, [shiftedAudio, startSpeaking]);
}, [startSpeaking]);

  
  useEffect ( () => {
    // Reset values after audio played
    if (audioPlayed) {
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
    }
    if (!audioPlayed) {
      // 7/21/2024
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
    }
    // Once audio Played, transition segment from PassageForeign to PassageEnglish
    if (audioPlayed) {
      dispatch(
        updateGlobalValue({ key: "current-layout", value: "passage-english" })
      );
    }
} , [audioPlayed]);

  // useEffect(() => {
  //   const playAudio = async () => {
  //     if (translationData && !audioPlayed && startSpeaking) {
  //       try {
  //         const result = await playWavBlob(passage1);
  //         if (result) {
  //           console.log("Audio finished playing successfully.");
  //           // Perform additional actions if necessary
  //         }
  //       } catch (error) {
  //         console.error("Audio playback failed:", error);
  //       }
  //     }
  //   };

  //   if (translationData && !audioPlayed && startSpeaking) {
  //     // Deprecated, Get ChatGPT to play the translationData, set in LanguageSelect
  //     // handlePlayAudio(translationData);

  //     playAudio(passage1);

  //     // playAudio(translationData);
  //   }
  //   // Reset values after audio played
  //   if (audioPlayed) {
  //     dispatch(
  //       updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
  //     );
  //   }
  //   if (!audioPlayed) {
  //     // 7/21/2024
  //     dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
  //   }
  // }, [translationData, audioPlayed, startSpeaking]);

  // // Once audio Played transition to PassageEnglish view
  // useEffect(() => {
  //   if (audioPlayed) {
  //     dispatch(
  //       updateGlobalValue({ key: "current-layout", value: "passage-english" })
  //     );
  //   }
  // }, [audioPlayed]);

  return (
    // <div className="translation-column">
    <div className="column">
      <div className="listen-countdown">
        <div className="countdown">
          <div className="text">Listen to this passage.</div>
          <CountDownTimer
            isTimerComplete={passageAudioCountdownComplete}
            setIsTimerComplete={setPassageAudioCountdownComplete}
            stepperLabel="language-countdown"
            disableCircularProgressbar={true}
          />
        </div>
        <div className="speaker">
          <SoundwaveTransition
            isTimerComplete={passageAudioCountdownComplete}
            startSpeaking={startSpeaking}
            setStartSpeaking={setStartSpeaking}
            audioPlayed={audioPlayed}
            stepperLabel="passage-foreign"
            classlabel="system-response"
          />
        </div>

        <div className="microphone-container">
          <div className="microphone">
            {displaySvgIcon("inactive-microphone")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PassageForeign);
