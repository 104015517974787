import { serializeAudio } from "../../utils/serializeAudio";

import chinese1 from "../../assets/audio/chinese/passage1_chinese.wav";
import chinese2 from "../../assets/audio/chinese/passage2_chinese.wav";
import chinese3 from "../../assets/audio/chinese/passage3_chinese.wav";
import chinese4 from "../../assets/audio/chinese/passage4_chinese.wav";
import chinese5 from "../../assets/audio/chinese/passage5_chinese.wav";

import english1 from "../../assets/audio/english/passage1_english.wav";
import english2 from "../../assets/audio/english/passage2_english.wav";
import english3 from "../../assets/audio/english/passage3_english.wav";
import english4 from "../../assets/audio/english/passage4_english.wav";
import english5 from "../../assets/audio/english/passage5_english.wav";

import hindi1 from "../../assets/audio/hindi/passage1_hindi.wav";
import hindi2 from "../../assets/audio/hindi/passage2_hindi.wav";
import hindi3 from "../../assets/audio/hindi/passage3_hindi.wav";
import hindi4 from "../../assets/audio/hindi/passage4_hindi.wav";
import hindi5 from "../../assets/audio/hindi/passage5_hindi.wav";

import korean1 from "../../assets/audio/korean/passage1_korean.wav";
import korean2 from "../../assets/audio/korean/passage2_korean.wav";
import korean3 from "../../assets/audio/korean/passage3_korean.wav";
import korean4 from "../../assets/audio/korean/passage4_korean.wav";
import korean5 from "../../assets/audio/korean/passage5_korean.wav";

import portuguese1 from "../../assets/audio/portuguese/passage1_portuguese.wav";
import portuguese2 from "../../assets/audio/portuguese/passage2_portuguese.wav";
import portuguese3 from "../../assets/audio/portuguese/passage3_portuguese.wav";
import portuguese4 from "../../assets/audio/portuguese/passage4_portuguese.wav";
import portuguese5 from "../../assets/audio/portuguese/passage5_portuguese.wav";

import spanish1 from "../../assets/audio/spanish/passage1_spanish.wav";
import spanish2 from "../../assets/audio/spanish/passage2_spanish.wav";
import spanish3 from "../../assets/audio/spanish/passage3_spanish.wav";
import spanish4 from "../../assets/audio/spanish/passage4_spanish.wav";
import spanish5 from "../../assets/audio/spanish/passage5_spanish.wav";

export const chi1 = serializeAudio(chinese1);
export const chi2 = serializeAudio(chinese2);
export const chi3 = serializeAudio(chinese3);
export const chi4 = serializeAudio(chinese4);
export const chi5 = serializeAudio(chinese5);

export const eng1 = serializeAudio(english1);
export const eng2 = serializeAudio(english2);
export const eng3 = serializeAudio(english3);
export const eng4 = serializeAudio(english4);
export const eng5 = serializeAudio(english5);

export const hin1 = serializeAudio(hindi1);
export const hin2 = serializeAudio(hindi2);
export const hin3 = serializeAudio(hindi3);
export const hin4 = serializeAudio(hindi4);
export const hin5 = serializeAudio(hindi5);

export const kor1 = serializeAudio(korean1);
export const kor2 = serializeAudio(korean2);
export const kor3 = serializeAudio(korean3);
export const kor4 = serializeAudio(korean4);
export const kor5 = serializeAudio(korean5);

export const por1 = serializeAudio(portuguese1);
export const por2 = serializeAudio(portuguese2);
export const por3 = serializeAudio(portuguese3);
export const por4 = serializeAudio(portuguese4);
export const por5 = serializeAudio(portuguese5);

export const spa1 = serializeAudio(spanish1);
export const spa2 = serializeAudio(spanish2);
export const spa3 = serializeAudio(spanish3);
export const spa4 = serializeAudio(spanish4);
export const spa5 = serializeAudio(spanish5);