// import React, { useEffect, useState, useRef } from 'react';
// import {truncateText} from '../utils/truncate'; // Adjust the path according to your file structure

import React, { useEffect, useState, useRef } from 'react';
import { truncateText } from '../utils/truncate'; // Adjust the path according to your file structure

const TextComponent = ({ text }) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const containerRef = useRef(null);

  const updateTruncatedText = () => {
    const container = containerRef.current;
    if (container) {
      const lineHeight = getComputedStyle(container).lineHeight;
      const truncated = truncateText(text, container, lineHeight);
      setTruncatedText(truncated);
    }
  };

  useEffect(() => {
    updateTruncatedText();

    const resizeObserver = new ResizeObserver(() => {
      updateTruncatedText();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [text]);

  // console.log("Description2:", truncatedText.length);
  return (
    <span className="instructions" ref={containerRef}>
      {truncatedText.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== truncatedText.split('\n').length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  );
};

export default TextComponent;
