import React, { useEffect } from "react";
import MicrophoneSpeakTimer2 from "../../global/outputs/MicrophoneSpeakTimer2";
import SilentMicrophoneAnimation from "../../global/widgets/SilentMicrophoneAnimation";
import VoiceMicrophoneAnimation from "./VoiceMicrophoneAnimation";
import MicComplete from "../../global/widgets/MicComplete";
import displaySvgIcon from "../../utils/displaySvgIcon";
import { updateGlobalValue } from "../../redux/slices/scenarioSlice";
import { useSelector, useDispatch } from "react-redux";

/**
 * ActiveMicrophone2 Component
 *
 * This component is responsible for managing the display and state of the microphone during a recording session.
 * It conditionally renders different microphone states based on the props passed to it.
 * - When `isTimerComplete` is false, it displays an inactive microphone icon.
 * - When `isTimerComplete` is true and `type` is "silent" and `isDataUploaded` is false, it displays an animated microphone ready state.
 * - When `isTimerComplete` is true and `type` is "voice" and `isDataUploaded` is false, it displays a microphone toggle state.
 * - When `isTimerComplete` is true and `isDataUploaded` is true, it displays a microphone completion state.
 * Additionally, it includes the `MicrophoneSpeakTimer2` component to display the countdown timer during the recording.
 * IMPORTANT: CALL MICROPHONESPEAKTIMER2, which is required to update time in the CircularProgressIndicator2 component.
 * 
 * @param {Object} props - The props for the component.
 * @param [Optional] {string} props.stepperLabel - The label for the current step in the stepper.  It is used in Type1 only..
 * @param {boolean} props.isTimerComplete - Flag indicating if the timer is complete.
 * @param {string} props.type - The type of microphone state ("silent" or "voice").
 * @param {boolean} props.isDataUploaded - Flag indicating if the data is uploaded.
 * @param {function} props.setUserSpeakTimeComplete - Callback to set the speak time complete state.
 * @param {number} props.recordingDuration - The initial time for the microphone record timer.
 * @returns {JSX.Element} The rendered component.
 */
const ActiveMicrophone2 = ({
  isTimerComplete,
  type,
  isDataUploaded,
  setSpeakTimeComplete,
  classLabel,
  recordingDuration,
  recordingUnit,
  stepperLabel,

}) => {
  // console.log("Recording Duration0:", recordingDuration, "RecordingUnit:", recordingUnit);
  const scenarioData = useSelector((state) => state.scenarios);
  const currentActivityType = scenarioData.global["current-activity-type"];

  const dispatch = useDispatch();
  // console.log("Stepper Label", stepperLabel);
  useEffect(() => {
    if (stepperLabel !== undefined) {
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: stepperLabel,
        })
      );
    }
  }, []);
  return (
    <>
      {!isTimerComplete ? (
        /***************************
         * (1) INACTIVE MICROPHONE
         ***************************/
        displaySvgIcon("inactive-microphone")
      ) : isTimerComplete && type === "silent" && !isDataUploaded ? (
      // ) : isTimerComplete && type === "silent" ? (

        /***************************
         * (2) SILENT MICROPHONE
         ***************************/
        <SilentMicrophoneAnimation classLabel="microphone-toggle" />
      ) : // <></>
      // isTimerComplete && type === "voice" && !isDataUploaded ? (
        isTimerComplete && type === "voice" ? (

        /************************************
         * (3) VOICE DETECTED MICROPHONE
         ************************************/
        <VoiceMicrophoneAnimation classLabel="microphone-toggle" />
      ) : // <></>
      isTimerComplete || isDataUploaded ? (
        /*************************************
         * (4) TIME UP MICROPHONE
         *************************************/
        <SilentMicrophoneAnimation classLabel="microphone-toggle" />
      ) : (
        <MicComplete />
      )}

      {isTimerComplete ? (
        /********************************************
         * (5) ORIGINAL SPEAK MESSAGE & TIMER: 
         * NOTE: TIMER NO LONGER DISPLAYED
         * NOTE: ONLY SHOULD BE DISPLAYED FOR 
         * ACTIVITYTYPE: PASSAGE
         ********************************************/
        <MicrophoneSpeakTimer2
          setSpeakTimeComplete={setSpeakTimeComplete}
          endTime={0}
          isDataUploaded={isDataUploaded}
          // clockTime={recordingDuration}
          recordingDuration={recordingDuration}
          recordingUnit={recordingUnit}
          isTimerComplete={isTimerComplete}
          disableCircularProgressbar={false}
        />
      ) : (
        <></>
      )}
      {/* </div> */}
    </>
  );
};

export default ActiveMicrophone2;
