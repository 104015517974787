export const serializeAudio = (audio) => {
    console.log("AUDIO:", audio);
    if (typeof audio === 'string') {
      console.log("Returning audio:", audio);
      return audio;
    }
    if (audio?.default) {
      return audio.default;
    }
    if (audio?.src) {
      return audio.src;
    }
    return ''; // Fallback for unsupported types
  };