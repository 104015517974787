/**
   * Set the portion of the option label to bold, if it matches the userinput
   * @param {*} label
   * @param {*} userInput
   * @returns
   */
const getFormattedMatch = (label, userInput) => {
    const escapedInput = userInput.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedInput, "gi");
    const replacedLabel = label.replaceAll(
      regex,
      (match) => `<span style="font-weight: 800">${match}</span>`
    );
    return <div dangerouslySetInnerHTML={{ __html: replacedLabel }} />;
  };

  export default getFormattedMatch;