import displaySvgIcon from "../utils/displaySvgIcon";

/**
 * BlockImageContainer component to render a list of images in a block with labels underneath.
 * This component is responsible for grouping the images in each row of the multi-dimensional array.
 * 
 * @param {Object} props - The component props.
 * @param {string[]} props.images - The array of image URLs to display.
 * @param {string[]} props.labels - The array of labels to display under the images.
 * @returns {JSX.Element} The rendered block of images with labels.
 */

const BlockImageContainer = ({ images, labels }) => (
    <div className="block-container">
        {images?.map((image, index) => (
            <div key={index} className="image-label-container">
                {typeof image === 'string' ? (
                    // If image is just a URL string
                    <div className="image-background" style={{ backgroundImage: `url(${image})` }}></div>
                ) : (
                    // If image is an object with a type property
                    image.type === 'text' ? (
                        <div className={image.plan === 'new' ? 'new-plan' : 'original-plan'}>
                            {displaySvgIcon(image.icon)}
                            {console.log("image1:", image)}
                            <br />
                            <p className={image?.data[0]?.className}>{image?.data[0]?.text}</p>

                            {image.data[1]?.className === 'image-description' ? (
                                <img src={image.data[1]?.url} alt={image.data[1]?.url} />
                            ) : image.data[1]?.className === 'list' ? (
                                <ul>
                                    {image.data[1]?.text.split(',').map((listItem, listIndex) => (
                                        <li key={listIndex}>{listItem.trim()}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className={image.data[1]?.className}>{image.data[1]?.text}</p>
                            )}
                        </div>
                    ) : (
                        // Fallback for other cases
                        <div className="image-background" style={{ backgroundImage: `url(${image})` }}></div>
                    )
                )}
                <div className="label">{labels[index]}</div>
            </div>
        ))}
    </div>
);

export default BlockImageContainer;
