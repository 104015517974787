export const serializeImage = (image) => {
  console.log("IMAGE:", image);

  if (typeof image === 'string') {
    console.log("Returning image:", image);
    return image;
  }
  if (image?.default) {
    return image.default;
  }
  if (image?.src) {
    return image.src;
  }
  if (typeof image === 'function') {
    return image.toString(); // Convert SVG React component to string identifier
  }
  if (image.type === "text") {
    return image.data.join(', ');
  }
  return ''; // Fallback for unsupported types
};
