import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAdviceValue,
  updateGlobalValue,
  updateKeyByActivity
} from "../../redux/slices/scenarioSlice";
import PropTypes from "prop-types";

import { minutesToSeconds } from "../../utils/minutesToSeconds";
/**
 * CountDownTimer Component: Displays initial Count Down Timer,,
 * before the User starts speaking.  
 * IMPORTANT: CountDownTimer is totally independent of CountDownBar
 *
 * Props:
 * - stepperLabel: (optional) A label to identify the current step of a process.
 * - isTimerComplete: A boolean that indicates if the timer has completed.
 * - setIsTimerComplete: A function to set the isTimerComplete state in the parent component.
 * - label: (optional) A string to display a label before the countdown number.
 * - delay: (optional) A delay in milliseconds before the timer starts.
 * - duration: The duration of the countdown timer in seconds.
 * - disableCircularProgressbar: A boolean that disables updates to the CountDownTimer from setting the countdown-progress-percentage in redux
 */
const CountDownTimer = ({
  stepperLabel,
  isTimerComplete,
  setIsTimerComplete,
  label,
  delay,
  duration,
  durationUnit,
  disableCircularProgressbar,
  classLabel,
  setCurrentCountdownTime,
}) => {
  const endingSegments = [
    "passage-english"];
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = scenarioData.global;
  const currentActivityType = globalData["current-activity-type"];
  const currentLayout = globalData["current-layout"];
  const currentStepperSegment = globalData["current-stepper-segment"];
  const currentSegmentIsTheEndingSegment = endingSegments.includes(
    currentStepperSegment
  );
  /*****************************************
   * You must set a default duration for every scenario
   * as the first time the app loads the duration will be 
   * undefined
   *****************************************/
  const [countdown, setCountdown] = useState(() => {
    let durationInSeconds = 0;
    if (durationUnit === "minutes" || durationUnit === "minute") {
    durationInSeconds = minutesToSeconds(duration)
    }
    else {
      durationInSeconds = duration;
    }
    // chat comes in with a 1, so we must convert to 60
    if (durationInSeconds && typeof durationInSeconds === "number" && durationInSeconds > 1) {
      return durationInSeconds;
    } else if (currentActivityType === "passage") {
      return 5;
    } else if (currentActivityType === "advice") {
      return 45;
    // } else if (currentActivityType === "chat") {
    //   return 60;
    } else {
      return 60; // Default value
    }
    // return durationInSeconds;
});
  const [timerStarted, setTimerStarted] = useState(false);

  const dispatch = useDispatch();
  // const currentPage = useSelector((state) => state.scenarios.global["current-page"]);

  // Function to calculate the percentage of time remaining
  const calculatePercentage = (currentTime, totalTime) => {
    const percentage = 100 - ((totalTime - currentTime) / totalTime) * 100;
    // console.log("*percentage", percentage, "TotalTIme:", totalTime, "currentTime:", currentTime);
    return percentage;
  };

  // Function to start the countdown timer
  const startTimer = () => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        // console.log("*prevCountdown", prevCountdown);
        const newCountdown = prevCountdown - 1;
        // console.log("*newCountdown", newCountdown, "duration", duration);
        // Dispatch the percentage to Redux only if a duration is sent
        if (
          !disableCircularProgressbar &&
          duration !== null &&
          duration !== undefined
        ) {
          // dispatch(
          //   updateKeyByActivity(({
          //     activityType: currentActivityType,
          //     key: "countdown-progress-percentage",
          //     value: calculatePercentage(),
          //   }))
          // );
        } else if ( (
          !disableCircularProgressbar &&
          duration === null &&
          duration === undefined)
          &&
          currentActivityType === "advice" ){
            // console.log("*CurrentCountDown", countdown, "duration:",duration, "isTimerComplete:", isTimerComplete);
        }

        if (newCountdown <= 0) {
          clearInterval(timer);
          setIsTimerComplete(true); // Signal that timer is complete
          return 0;
        }

        return newCountdown;
      });
    }, 1000);

    // Return cleanup function to clear the interval
    return () => clearInterval(timer);
  };

  // Effect to start the timer when delay is not provided
  useEffect(() => {
    // console.log("CountDownTimer: timerStarted:", timerStarted, "delay:", delay);
    if (!delay && !timerStarted) {
      startTimer();
      setTimerStarted(true);
    }
  }, [delay, timerStarted]);

  // Effect to delay the start of the timer when delay is provided
  useEffect(() => {
    let timerDelay;
    if (delay && !timerStarted) {
      timerDelay = setTimeout(() => {
        startTimer();
        setTimerStarted(true);
      }, delay);
    }
    if (timerStarted && stepperLabel && 
      currentActivityType !== "chat" &&
      currentActivityType !== "opinion") {
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: stepperLabel,
        })
      );
    }

    // Cleanup function to clear the delay timeout if unmounted or delay changes
    return () => clearTimeout(timerDelay);
  }, [delay, timerStarted]);

  useEffect(() => {
    if (setCurrentCountdownTime) {
      setCurrentCountdownTime(countdown);
    }
  }, [countdown]);

  // Effect to dispatch 100% completion when the timer completes
  useEffect(() => {
    // const percentage = calculatePercentage(countdown, duration);
    if (isTimerComplete) {
      // dispatch(
          //   updateKeyByActivity(({
          //     activityType: currentActivityType,
          //     key: "countdown-progress-percentage",
          //     value: calculatePercentage(),
          //   }))
          // );
    }
  }, [isTimerComplete, dispatch]);

  useEffect(() => {
    console.log(
      "duration",
      isTimerComplete,
      "Duration:",
      duration,
      "StepperLabel:",
      stepperLabel
    );
  }, []);

  return (
    <div
      className={`${
        isTimerComplete ? "count-down-text hidden-element" : "count-down-text"
      }${classLabel ? ` ${classLabel}` : ""}`}
    >
      {label ? `${label}...` : "Starting in..."}
      <span className="countdown-number">{countdown}</span>
    </div>
  );
};
CountDownTimer.propTypes = {
  stepperLabel: PropTypes.string,
  isTimerComplete: PropTypes.bool.isRequired,
  setIsTimerComplete: PropTypes.func.isRequired,
  label: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
  disableCircularProgressbar: PropTypes.bool,
};
export default CountDownTimer;
