 /**
   * Set a new option format for react-select component
   * @param {*} options
   * @returns
   */
 import { components } from "react-select";
import getFormattedMatch from "./getFormattedMatch";

 export const IconOption = (options) => {
  // console.log("OPTIONS:", options);
    const { Option } = components;

    const userInput = options?.selectProps?.inputValue || "";
    const { data } = options;
    // console.log("data", data);
    // console.log("userInput", userInput);
    return (
      <Option {...options}>
        <div className="option-container">
        {/* {data.SvgComponent && <data.SvgComponent style={{ width: '45px', height: '70px' }} />} */}
          <img
            src={data.imagePath}
            style={{ width: "22.4px", height: "16px" }}
            alt={data.label}
          />
          {/* {data.SvgComponent} */}
          <div className="option-details">
            <p>
              {userInput?.length
                ? data.label?.split("").length
                  ? getFormattedMatch(data.label, userInput)
                  : data.label
                : data.label}
            </p>
            {/* <p>{data.authors}</p>
            <p>{data.isbn}</p> */}
          </div>
        </div>
      </Option>
    );
  };
