// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your Free Spark Account web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBqeZ58hfQWcNkytlx9rRISD1U3gVwHpJU",
//   authDomain: "pte-a-ff6c9.firebaseapp.com",
//   projectId: "pte-a-ff6c9",
//   storageBucket: "pte-a-ff6c9.appspot.com",
//   messagingSenderId: "45722664725",
//   appId: "1:45722664725:web:094568c4ca5b0869bb731c"
// };

// Your Blaze Spark Account web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCnp1-5nohQYu5vYZbWwGCljbdYkpOp2us",
  authDomain: "mondly-workflows.firebaseapp.com",
  databaseURL: "https://mondly-workflows-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mondly-workflows",
  storageBucket: "mondly-workflows.appspot.com",
  messagingSenderId: "79514037193",
  appId: "1:79514037193:web:9b91f98854c8a19fa55618"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);