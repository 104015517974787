import React, { useEffect, useState, useRef } from "react";
import listeningSpeaker from "../../assets/images/audio/listening-speaker.png";
import speakingSpeaker from "../../assets/images/audio/audio-speaker.png";
import displaySvgIcon from "../../utils/displaySvgIcon";
import { updateGlobalValue } from "../../redux/slices/scenarioSlice";
import { useSelector, useDispatch } from "react-redux";
// import SystemActive from "./animations/SystemActive";
import SystemListeningAlternative from "./animations/SystemListeningAlternative";
import SystemListening from "./animations/SystemListening";
import SystemSpeaking from "./animations/SystemSpeaking";
import SystemThinkingAlternative from "./animations/SystemThinkingAlternative";
/**
  
 **/
/**
 * Transitions the System soundwave from inactive to active
 * when startSpeaking is true and back to inactive when audioPlayed
 * is true
 *
 * Note: Default is an inactive speaker when optional props not passed
 * @param {Boolean} isTimerComplete (Optional)
 * @param {Boolean} startSpeaking (Optional)
 * @param {Boolean} setStartSpeaking (Optional)
 * @param {Boolean} audioPlayed(Optional)
 **/
const SoundwaveTransition = ({
  stepperLabel,
  classLabel,
  isTimerComplete,
  startSpeaking,
  setStartSpeaking,
  // phraseDuration,
  audioPlayed,
}) => {
  const dispatch = useDispatch();
  const riveRef = useRef(null);

  useEffect(() => {
    console.log(
      "isTimerComplete9:",
      isTimerComplete,
      "startSpeaking:",
      startSpeaking,
      "audioPlayed:",
      audioPlayed
    );
    if (isTimerComplete && setStartSpeaking) {
      setStartSpeaking(true);
      if (stepperLabel) {
        dispatch(
          updateGlobalValue({
            key: "current-stepper-segment",
            value: stepperLabel,
          })
        );
      }
    }
  }, [isTimerComplete, setStartSpeaking]);

  useEffect(() => {
    if (startSpeaking && audioPlayed) {
      setStartSpeaking(false);
    }
    console.log("Start Speaking:", startSpeaking);
  }, [startSpeaking, audioPlayed]);
  return (
    <div className={classLabel}>
      {startSpeaking ? (
        <>
          <div className="headphones">{displaySvgIcon("headphones")}</div>
         
          {/* <SystemSpeaking /> */}
          <SystemSpeaking />
        </>
      ) : !startSpeaking && !audioPlayed && !isTimerComplete ? (
        <>
          <div className="headphones"></div>
          {/* <SystemListening /> */}
          <SystemListening />
        </>
      ) : (
        <>
          <div className="headphones"></div>
          <SystemListeningAlternative  />
        </>
      )}
    </div>
  );
};

export default SoundwaveTransition;
