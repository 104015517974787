export const analyzeRealTimeAudioWithWebSpeechAPI = (stream, audioContext) => {
  return new Promise((resolve) => {
    // Check for the Web Speech API
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("Web Speech API not supported");
      resolve(false); // Web Speech API is not supported, resolve with false
      return;
    }

    const recognizer = new SpeechRecognition();
    recognizer.lang = "en-US";
    recognizer.continuous = false;
    recognizer.interimResults = false;

    // Removed the guts
    recognizer.onresult = (event) => {
      console.log("Results:", event.results);
      // Results will show your transcript of what you said
      const transcript = event.results[0][0].transcript;
      const confidence = event.results[0][0].confidence;
      console.log(
        "AnalyzerEvent: Transcript:",
        transcript,
        "Confidence:",
        confidence
      );
      if (transcript.length > 0) {
            resolve(true); // Resolve with true if speech is detected
          } else {
            resolve(false); // Resolve with false if no speech is detected
          }
    };

    recognizer.onerror = (event) => {
        // console.error("Speech recognition error:", event.error);
        resolve(false); // Resolve with false if there's an error
      };
      
      recognizer.onend = () => {
        // console.log("Speech recognition ended");
        // Resolve to false if no result or error event fired
        resolve(false);
      };
    

    // Attach the MediaStream to the recognizer
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);
    recognizer.start();
  });
};

// the guts:
// recognizer.onaudiostart = () => {
//   console.log("Audio recording started");
// };

// recognizer.onresult = (event) => {
//   const transcript = event.results[0][0].transcript;
//   console.log("Transcript received:", transcript);
//   if (transcript.length > 0) {
//     resolve(true); // Resolve with true if speech is detected
//   } else {
//     resolve(false); // Resolve with false if no speech is detected
//   }
// };

// recognizer.onerror = (event) => {
//   console.error("Speech recognition error:", event.error);
//   resolve(false); // Resolve with false if there's an error
// };

// recognizer.onend = () => {
//   console.log("Speech recognition ended");
//   // Resolve to false if no result or error event fired
//   resolve(false);
// };
