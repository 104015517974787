import React, { useEffect, useState, useRef } from "react";
import listeningSpeaker from "../../../assets/images/audio/listening-speaker.png";
import speakingSpeaker from "../../../assets/images/audio/audio-speaker.png";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import { updateGlobalValue } from "../../../redux/slices/scenarioSlice";
import { useSelector, useDispatch } from "react-redux";
// import SystemActive from "./animations/SystemActive";
import SystemListeningAlternative from "./animations/SystemListeningAlternative";
import SystemListening from "./animations/SystemListening";
import SystemSpeaking from "./animations/SystemSpeaking";
import SystemThinking from "../animations/SystemThinking";
// import SystemThinkingAlternative from "./animations/SystemThinkingAlternative";
/**
  
 **/
/**
 * Transitions the System soundwave from inactive to active
 * when startSpeaking is true and back to inactive when audioPlayed
 * is true
 *
 * Note: Default is an inactive speaker when optional props not passed
 * @param {Boolean} isTimerComplete (Optional)
 * @param {Boolean} startSpeaking (Optional)
 * @param {Boolean} setStartSpeaking (Optional)
 * @param {Boolean} audioPlayed(Optional)
 **/
const SoundwaveTransition = ({
  stepperLabel,
  classLabel,
  isTimerComplete,
  // startSpeaking,
  // setStartSpeaking,
  // phraseDuration,
  setAudioPlayed,
  audioPlayed,
  audioPlayedRef,
  systemSpeaking,
  systemSpeakingRef,
  /**************/
  isTalkingForFiveSeconds,
  isSilentForFiveSeconds,
  startedTalking,
  prevType,
  type,
  isRecording,
  isDataUploaded
}) => {
  const dispatch = useDispatch();
  const riveRef = useRef(null);

  useEffect(() => {
    console.log(
      "isTimerComplete9:",
      isTimerComplete,
      // "startSpeaking:",
      // startSpeaking,
      "audioPlayed:",
      audioPlayed,
      "audioPlayedRef:",
      audioPlayedRef.current,
      "SystemIsSpeaking:",
      systemSpeaking,
      "systemSpeakingRef:",
      systemSpeakingRef
    );
    /// THIS IS WRONG
    // if (isTimerComplete && setStartSpeaking) {
    //   setStartSpeaking(true);
    //   if (stepperLabel) {
    //     dispatch(
    //       updateGlobalValue({
    //         key: "current-stepper-segment",
    //         value: stepperLabel,
    //       })
    //     );
    //   }
    // }
  }, [isTimerComplete, audioPlayed, audioPlayedRef?.current, systemSpeaking]);

  useEffect(() => {
    if (audioPlayedRef) {
      // setStartSpeaking(false);
      // setAudioPlayed(false);
    }
    console.log("Start Speaking:", systemSpeaking);
  }, [audioPlayed]);
  return (
    <div className={classLabel}>

      {console.log("SoundwaveTransition: SystemSpeaking:",
        systemSpeaking,
        // "ActivityCountdownTimerComplete:",
        // activityCoundownTimerComplete,
        "startedTalking:",
        startedTalking,
        "audioPlayed:",
        audioPlayed,
        "audioPlayedRef:",
        audioPlayedRef.current,
        "SystemIsSpeaking:",
        systemSpeaking,
        "systemSpeakingRef:",
        systemSpeakingRef,
        "isTalkingForFiveSeconds:",
        isTalkingForFiveSeconds,
        "isSilentForFiveSeconds",
        isSilentForFiveSeconds,
        "startedTalking:",
        startedTalking,
        "prevType:",
        prevType,
        "IsRecording:",
        isRecording,
        "Type:",
        type,
        "isDataUploaded:",
        isDataUploaded)}
      {/* The time between when the audio is sent and play completes */}
      {systemSpeaking ? (
        <>
          <div className="headphones">{displaySvgIcon("headphones")}</div>

          {/* <SystemSpeaking /> */}
          <SystemSpeaking />
        </>
      ) : !systemSpeaking &&
        !isRecording &&
        isTimerComplete &&
        type === "silent" &&
        !isDataUploaded ? (
        <>
          <div className="headphones hidden-element">{displaySvgIcon("headphones")}</div>
          <SystemThinking />
        </>
      ) :
        !audioPlayedRef && !isTimerComplete ? (
          <>
            <div className="headphones hidden-element">{displaySvgIcon("headphones")}</div>
            {/* <SystemListening /> */}
            <SystemListening />
          </>
        ) : (
          <>
            <div className="headphones hidden-element">{displaySvgIcon("headphones")}</div>
            <SystemListeningAlternative />
          </>
        )}
    </div>
  );
};

export default SoundwaveTransition;
