import React from "react";
import AudioTemplate from "../global/wrappers/AudioTemplate";
import { useDispatch, useSelector } from "react-redux";

const Advice = ({ children }) => {
  console.log("Advice re-rendered");
  const currentLayout = useSelector(
    (state) => state.scenarios.global["current-layout"]
  );
  const currentPage = useSelector(
    (state) => state.scenarios.global["current-page"]
  );

  return (
    <div className={currentPage === "advice" && currentLayout !=="advice-instructions"? "page advice" : "page"}>
       {children}
      </div>
)};

export default Advice;
