import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";


const CountUpTimer = () => {
  const scenarioData = useSelector((state) => state.scenarios);
  const currentLayout = scenarioData.global["current-layout"];
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const totalTime = 420; // 7 minutes in seconds

  const intervalRef = useRef(null);

  useEffect(() => {
    if (currentLayout !== "lesson-select" && intervalRef.current === null) {
      let totalSeconds = minutes * 60 + parseInt(seconds, 10);
      intervalRef.current = setInterval(() => {
        totalSeconds++;
        if (totalSeconds >= totalTime) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          totalSeconds = totalTime;
        }
        setSeconds(pad(totalSeconds % 60));
        setMinutes(pad(parseInt(totalSeconds / 60)));
      }, 1000);
    }

    return () => {
      if (currentLayout === "lesson-select" && intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [currentLayout, minutes, seconds]);

  const pad = (val) => {
    const valString = val + "";
    return valString.length < 2 ? "0" + valString : valString;
  };

  return (
    <div className="content time-up">
      <span>{minutes}</span>:<span>{seconds}</span>
      <span>
        &nbsp;/{pad(parseInt(totalTime / 60))}:{pad(totalTime % 60)}
      </span>
    </div>
  );
};

export default CountUpTimer;
