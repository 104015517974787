/**
 * Helper function to calculate the height of a given text
 * within a specified width and font.
 * 
 * @param {string} text - The text to measure.
 * @param {string} font - The font to be used for the text.
 * @param {number} width - The width of the container.
 * @returns {number} - The height of the text.
 */
export const getTextHeight = (text, font, width) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.visibility = 'hidden';
  div.style.width = `${width}px`;
  div.style.font = font;
  div.style.lineHeight = '1.5'; // Adjust this if necessary to match the actual line height
  div.style.whiteSpace = 'normal'; // Allow wrapping
  div.innerText = text;
  document.body.appendChild(div);
  const height = div.offsetHeight;
  document.body.removeChild(div);
  return height;
};

/**
 * Function to truncate text if it exceeds the specified container height.
 * 
 * @param {string} text - The text to be truncated.
 * @param {number} containerHeight - The height of the container.
 * @param {string} font - The font used for the text.
 * @param {number} containerWidth - The width of the container.
 * @returns {string} - The truncated text with ellipsis if necessary.
 */
export const truncateText = (text, container, lineHeight) => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.width = `${container.offsetWidth}px`;
  tempDiv.style.fontSize = getComputedStyle(container).fontSize;
  tempDiv.style.fontWeight = getComputedStyle(container).fontWeight;
  tempDiv.style.fontFamily = getComputedStyle(container).fontFamily;
  tempDiv.style.lineHeight = lineHeight;
  tempDiv.style.whiteSpace = 'normal';
  document.body.appendChild(tempDiv);

  let truncated = text;
  let currentHeight = 0;

  for (let i = text.length; i >= 0; i--) {
    tempDiv.innerText = text.slice(0, i) + '...';
    currentHeight = tempDiv.offsetHeight ;
    if (currentHeight  <= container.offsetHeight - 10) {
      truncated = text.slice(0, i) + '...';
      break;
    }
  }

  document.body.removeChild(tempDiv);
  let newText;
  // console.log("Description3:", truncated.length, "text Length:", text.length );
  if (truncated.length > text.length && truncated.length - text.length === 3) {
    newText = truncated.slice(0, -3);
  }
  else {
    newText = truncated;
  }
  return newText;
};







  
  export const measureTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  };
  