// Define constants
const SILENCE_THRESHOLD = 1.0; // Adjust this based on your noise level
const FRAME_SIZE = 1024; // Size of each frame in samples

// Function to trim silence from an audioBlob
// export const trimSilenceFromAudioBlob = async (audioBlob, audioContext) => {
//     // Convert the audioBlob to an ArrayBuffer
//     const arrayBuffer = await audioBlob.arrayBuffer();
//     // Decode the ArrayBuffer into an AudioBuffer
//     const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
//     // Get the audio data from the first channel (assuming mono audio)
//     const channelData = audioBuffer.getChannelData(0);

//     const sampleRate = audioBuffer.sampleRate;
//     const frameCount = Math.floor(channelData.length / FRAME_SIZE);

//     let start = 0, end = channelData.length;
//     let isSilent = true;

//     // Find the start index where the audio is not silent
//     for (let i = 0; i < frameCount; i++) {
//         // Get the audio data for the current frame
//         const frame = channelData.subarray(i * FRAME_SIZE, (i + 1) * FRAME_SIZE);
//         // Calculate the RMS value of the frame
//         const rms = calculateRMS(frame);
//         // Check if the RMS value is above the silence threshold
//         if (rms >= SILENCE_THRESHOLD) {
//             start = i * FRAME_SIZE;
//             isSilent = false;
//             break;
//         }
//     }

//     // Find the end index where the audio is not silent
//     if (!isSilent) {
//         for (let i = frameCount - 1; i >= 0; i--) {
//             // Get the audio data for the current frame
//             const frame = channelData.subarray(i * FRAME_SIZE, (i + 1) * FRAME_SIZE);
//             // Calculate the RMS value of the frame
//             const rms = calculateRMS(frame);
//             // Check if the RMS value is above the silence threshold
//             if (rms >= SILENCE_THRESHOLD) {
//                 end = (i + 1) * FRAME_SIZE;
//                 break;
//             }
//         }
//     }

//     // Create a new AudioBuffer for the trimmed audio
//     const trimmedBuffer = audioContext.createBuffer(
//         audioBuffer.numberOfChannels,
//         end - start,
//         audioBuffer.sampleRate
//     );

//     // Copy the trimmed audio data into the new AudioBuffer
//     for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
//         const channelData = audioBuffer.getChannelData(i);
//         trimmedBuffer.copyToChannel(channelData.subarray(start, end), i);
//     }

//     // Convert the trimmed AudioBuffer to a Blob and return it
//     return bufferToWave(trimmedBuffer);
// };

export const trimSilenceFromAudioBlob = async (audioBlob, audioContext) => {
    try {
        // Convert the audioBlob to an ArrayBuffer
        const arrayBuffer = await audioBlob.arrayBuffer();
        console.log("ArrayBuffer size:", arrayBuffer.byteLength);

        // Decode the ArrayBuffer into an AudioBuffer
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        console.log("Decoded AudioBuffer:", audioBuffer);

        // Get the audio data from the first channel (assuming mono audio)
        const channelData = audioBuffer.getChannelData(0);

        const sampleRate = audioBuffer.sampleRate;
        const frameCount = Math.floor(channelData.length / FRAME_SIZE);

        let start = 0, end = channelData.length;
        let isSilent = true;

        // Find the start index where the audio is not silent
        for (let i = 0; i < frameCount; i++) {
            // Get the audio data for the current frame
            const frame = channelData.subarray(i * FRAME_SIZE, (i + 1) * FRAME_SIZE);
            // Calculate the RMS value of the frame
            const rms = calculateRMS(frame);
            // Check if the RMS value is above the silence threshold
            if (rms >= SILENCE_THRESHOLD) {
                start = i * FRAME_SIZE;
                isSilent = false;
                break;
            }
        }

        // Find the end index where the audio is not silent
        if (!isSilent) {
            for (let i = frameCount - 1; i >= 0; i--) {
                // Get the audio data for the current frame
                const frame = channelData.subarray(i * FRAME_SIZE, (i + 1) * FRAME_SIZE);
                // Calculate the RMS value of the frame
                const rms = calculateRMS(frame);
                // Check if the RMS value is above the silence threshold
                if (rms >= SILENCE_THRESHOLD) {
                    end = (i + 1) * FRAME_SIZE;
                    break;
                }
            }
        }

        // Create a new AudioBuffer for the trimmed audio
        const trimmedBuffer = audioContext.createBuffer(
            audioBuffer.numberOfChannels,
            end - start,
            audioBuffer.sampleRate
        );

        // Copy the trimmed audio data into the new AudioBuffer
        for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
            const channelData = audioBuffer.getChannelData(i);
            trimmedBuffer.copyToChannel(channelData.subarray(start, end), i);
        }

        // Check the duration of the trimmed buffer
        if (trimmedBuffer.duration < 0.1) {
            // Create a buffer with the minimum length of 0.1 seconds
            const minLengthBuffer = audioContext.createBuffer(
                trimmedBuffer.numberOfChannels,
                0.1 * sampleRate,
                sampleRate
            );

            // Copy the trimmed buffer into the new buffer
            for (let i = 0; i < trimmedBuffer.numberOfChannels; i++) {
                minLengthBuffer.copyToChannel(trimmedBuffer.getChannelData(i), i);
            }

            return bufferToWave(minLengthBuffer);
        } else {
            // Convert the trimmed AudioBuffer to a Blob and return it
            return bufferToWave(trimmedBuffer);
        }

    } catch (error) {
        console.error("Error trimming silence from audio:", error);
        throw error; // Rethrow the error to handle it further up the chain
    }
};





// Function to calculate the RMS value of audio data
const calculateRMS = (dataArray) => {
    let sum = 0;
    // Sum the squares of the audio data values
    for (let i = 0; i < dataArray.length; i++) {
        const value = dataArray[i] / 128 - 1.0; // Normalize to -1.0 to 1.0
        sum += value * value;
    }
    // Return the square root of the mean of the squared values
    return Math.sqrt(sum / dataArray.length);
};

// Function to convert an AudioBuffer to a WAV Blob
const bufferToWave = (abuffer) => {
    let numOfChan = abuffer.numberOfChannels,
        length = abuffer.length * numOfChan * 2 + 44,
        buffer = new ArrayBuffer(length),
        view = new DataView(buffer),
        channels = [],
        i,
        sample,
        offset = 0,
        pos = 0;

    // Write the WAVE header
    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // File length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // Length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan); // Avg. bytes/sec
    setUint16(numOfChan * 2); // Block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // Chunk length

    // Write interleaved data
    for (i = 0; i < abuffer.length; i++) {
        for (let chan = 0; chan < numOfChan; chan++) {
            sample = Math.max(-1, Math.min(1, abuffer.getChannelData(chan)[i])); // Clamp
            sample = (sample < 0 ? sample * 0x8000 : sample * 0x7fff) | 0; // Scale to 16-bit signed int
            view.setInt16(pos, sample, true); // Write 16-bit sample
            pos += 2;
        }
    }

    return new Blob([buffer], { type: "audio/wav" });

    function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
    }

    function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
    }
};

// Example usage within mediaRecorder.onstop event handler
// mediaRecorder.current.onstop = async () => {
//     const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
//     audioChunks.current = [];
//     playAudioBlob(audioBlob);

//     console.log(
//         "isSilentForFiveSeconds:",
//         isSilentForFiveSeconds,
//         "@isSilentRef.current:",
//         isSilentRef.current
//     );

//     // Trim silence if the recording is not silent
//     if (!isSilentRef.current) {
//         const trimmedAudioBlob = await trimSilenceFromAudioBlob(audioBlob, audioContext.current);
//         processAudio(trimmedAudioBlob, chatHistory, false);
//     }
// };
