import {toast, cssTransition } from "react-toastify";
export const bounce = cssTransition({
    // zoomIn will become zoomIn--top-right or zoomIn--top-left and so on
    enter: "zoomIn",
    // zoomIn will become zoomOut--top-right or zoomOut--top-left and so on
    exit: "zoomOut",
    // default to false
    appendPosition: true,
  });

  // React Toatify:
 export const notify = () => {
    console.log("Notify should be triggered");
    return toast.error("Please speak louder.", {
      position: "bottom-center",
      theme: "colored",
      hideProgressBar: true,
      // transition: bounce,
    });
  };